<div class="container">
    <mat-toolbar color="primary" class="header">
        <h1>Netaxept</h1>
        <div class="filters">
            <button mat-flat-button class="small-button filters__button" color="accent" (click)="gotoNetaxeptPanel()">Netaxept Panel</button>
            <mat-form-field class="small-date-range" color="accent">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input  [formGroup]="dateRange" [rangePicker]="picker">
                    <input formControlName="start" matStartDate placeholder="Start date">
                    <input formControlName="end" matEndDate placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle class="small-date-range__date-picker" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <button mat-icon-button color="accent" aria-label="Clear filters" (click)="clearMainFilters()"
                    matTooltip="Clear date filters">
                <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            </button>

        </div>
    </mat-toolbar>

    <main class="content">

        <!-- GAUGE -->
        <mat-card>
            <mat-card-header>
                <mat-card-title class="header-title">Errors monitoring results of error rule</mat-card-title>
                <mat-card-subtitle>Percentage of '10% of errors reached in 1h slots' in the provided period</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-transaction-slots-gauge
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [errorsError]="true"
                        [errorsWarn]="null"
                        [warningsError]="null"
                        [warningsWarn]="null"
                ></app-transaction-slots-gauge>
            </mat-card-content>
        </mat-card>

        <!-- GAUGE -->
        <mat-card>
            <mat-card-header>
                <mat-card-title class="header-title">Errors monitoring result of warn rule </mat-card-title>
                <mat-card-subtitle>Percentage of '5% of errors reached in 1h slots' in the provided period</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-transaction-slots-gauge
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [errorsError]="false"
                        [errorsWarn]="true"
                        [warningsError]="null"
                        [warningsWarn]="null"
                        [isWarn]="true"
                ></app-transaction-slots-gauge>
            </mat-card-content>
        </mat-card>

        <!-- GAUGE -->
        <mat-card>
            <mat-card-header>
                <mat-card-title class="header-title">Warnings monitoring results of error rule</mat-card-title>
                <mat-card-subtitle>Percentage of '10% of warnings reached in 1h slots' in the provided period</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-transaction-slots-gauge
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [errorsError]="null"
                        [errorsWarn]="null"
                        [warningsError]="true"
                        [warningsWarn]="null"
                ></app-transaction-slots-gauge>
            </mat-card-content>
        </mat-card>

        <!-- GAUGE -->
        <mat-card>
            <mat-card-header>
                <mat-card-title class="header-title">Warnings monitoring results of warn rule</mat-card-title>
                <mat-card-subtitle>Percentage of '5% of warnings reached in 1h slots' in the provided period</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-transaction-slots-gauge
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [errorsError]="null"
                        [errorsWarn]="null"
                        [warningsError]="false"
                        [warningsWarn]="true"
                        [isWarn]="true"
                ></app-transaction-slots-gauge>
            </mat-card-content>
        </mat-card>


        <!-- CHART -->
        <mat-card class="content__one-column">
            <mat-card-header>
                <mat-card-title class="header-title">All Transactions</mat-card-title>
                <mat-card-subtitle>Transactions by status in time</mat-card-subtitle>
                <div class="filters">
                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Granularity</mat-label>
                        <mat-select [placeholder]="'Granularity'" [(ngModel)]="allTransactionChartBinFilter">
                            <mat-option [value]="1">1</mat-option>
                            <mat-option [value]="2">2</mat-option>
                            <mat-option [value]="3">3</mat-option>
                            <mat-option [value]="4">4</mat-option>
                            <mat-option [value]="5">5</mat-option>
                            <mat-option [value]="10">10</mat-option>
                            <mat-option [value]="15">15</mat-option>
                            <mat-option [value]="20">20</mat-option>
                            <mat-option [value]="30">30</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Time Unit</mat-label>
                        <mat-select [placeholder]="'Time Unit'" [(ngModel)]="allTransactionChartUnitFilter">
                            <mat-option value="minute">Minutes</mat-option>
                            <mat-option value="hour">Hours</mat-option>
                            <mat-option value="day">Days</mat-option>
                            <mat-option value="week">Weeks</mat-option>
                            <mat-option value="month">Months</mat-option>
                            <mat-option value="year">Years</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button color="accent" aria-label="Clear filters" (click)="clearTransactionTableFilters()"
                            matTooltip="Clear all filters">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                    </button>

                </div>
            </mat-card-header>
            <mat-card-content>
                    <app-transactions-chart
                            [unit]="allTransactionChartUnitFilter"
                            [bin]="allTransactionChartBinFilter"
                            [dateFrom]="dateRange.getRawValue().start"
                            [dateTo]="dateRange.getRawValue().end"
                    ></app-transactions-chart>
            </mat-card-content>
        </mat-card>

        <!--TABLE -->
        <mat-card class="content__one-column">
            <mat-card-header>
                <mat-card-title class="header-title">All Transactions</mat-card-title>
                <mat-card-subtitle>Detailed information</mat-card-subtitle>
                <div class="filters">
                    <mat-form-field class="small-input" color="accent">
                        <mat-label>Amount From</mat-label>
                        <input matInput [(ngModel)]="allTransactionTableAmountFromFilter" type="number">
                    </mat-form-field>

                    <mat-form-field class="small-input" color="accent">
                        <mat-label>Amount To</mat-label>
                        <input matInput  [(ngModel)]="allTransactionTableAmountToFilter" type="number">
                    </mat-form-field>


                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Status</mat-label>
                        <mat-select [placeholder]="'Status'"[(ngModel)]="allTransactionTableStatusFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="OK">OK</mat-option>
                            <mat-option value="WARNING">WARNING</mat-option>
                            <mat-option value="ERROR">ERROR</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Issuer</mat-label>
                        <mat-select [placeholder]="'Issuer'" [(ngModel)]="allTransactionTableIssuerFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="Dankort">Dankort</mat-option>
                            <mat-option value="MasterCard">MasterCard</mat-option>
                            <mat-option value="Visa">Visa</mat-option>
                            <mat-option value="Easypayment">Easypayment</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button color="accent" aria-label="Clear filters" (click)="clearTransactionTableFilters()"
                            matTooltip="Clear all filters">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <mat-card-content>
                <app-transactions-table
                        [statusFilter]="allTransactionTableStatusFilter"
                        [issuerFilter]="allTransactionTableIssuerFilter"
                        [amountFromFilter]="allTransactionTableAmountFromFilter"
                        [amountToFilter]="allTransactionTableAmountToFilter"
                        [dateStartFilter]="dateRange.getRawValue().start"
                        [dateEndFilter]="dateRange.getRawValue().end"
                ></app-transactions-table>
            </mat-card-content>
        </mat-card>

        <!-- TABLE -->
        <mat-card class="content__two-column content__span-two-rows">
            <mat-card-header>
                <mat-card-title>Errors</mat-card-title>
                <mat-card-subtitle>The first time and the last time when the error code showed up</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
               <app-error-codes-table></app-error-codes-table>
            </mat-card-content>
        </mat-card>

        <!-- CHART -->
        <mat-card class="content__two-column">
            <mat-card-header>
                <mat-card-title>Errors</mat-card-title>
                <mat-card-subtitle>Amount of errors by reason code</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-error-types-chart
                            [dateFrom]="dateRange.getRawValue().start"
                            [dateTo]="dateRange.getRawValue().end"
                    ></app-error-types-chart>
            </mat-card-content>
        </mat-card>

        <!--TABLE -->
        <mat-card class="content__two-column">
            <mat-card-header>
                <mat-card-title>Errors</mat-card-title>
                <mat-card-subtitle>Amount of errors by reason code</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-error-types-table
                        [dateStartFilter]="dateRange.getRawValue().start"
                        [dateEndFilter]="dateRange.getRawValue().end"
                >
                </app-error-types-table>
            </mat-card-content>
        </mat-card>
    </main>
</div>
