import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PAGE_SIZE_OPTIONS, RequestParams, SortDirections } from '../../../../models/list';
import { Sort } from '@angular/material/sort';
import { AriaWfService } from '../../services/aria-wf.service';
import { ToastService } from '../../../shared/services/toast.service';
import { ResponseList } from '../../../../models/response.model';
import { AriaWfLetterItem } from '../../../../models/aria-wf-letter-item.model';
import { TimeTools } from '../../../shared/common/time-tools';
import { LettersGenerationService } from '../../services/letters-generation.service';

@Component({
  selector: 'app-aria-wf-letter-items-table',
  templateUrl: './aria-wf-letter-items-table.component.html',
  styleUrls: ['./aria-wf-letter-items-table.component.scss']
})
export class AriaWfLetterItemsTableComponent implements  OnInit, OnChanges{
  @Input() ariaWfId:string = '';
  @Input() statusFilter = '';
  @Input() letterType:string = '';
  @Input() awaitingCreditMemo:boolean | string = '';
  @Input() dateFrom!:Date | string;
  @Input() dateTo!:Date | string;
  @Input() missing:boolean = false;
  @Input() displayedColumns: string[] = ['executionTime', 'ariaWfLetterId', 'status', 'letterType', 'invoiceNo', 'billingGroupId', 'salesForceCustomerId', 'scenarioCode', 'creditMemo' ];


  // public displayedColumns: string[] = ['executionTime', 'ariaWfLetterId', 'status', 'letterType', 'invoiceNo', 'billingGroupId', 'salesForceCustomerId', 'scenarioCode', 'creditMemo' ];
  public dataSource: AriaWfLetterItem[] = [];
  public totalCount = 0;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;
  public requestParams: RequestParams = {
    page: 0,
    limit: 25,
    sort: 'executionTime',
    order: SortDirections.DESC,
    status: this.statusFilter,
    awaitingCreditMemo:this.awaitingCreditMemo,
    letterType:this.letterType,
    ariaWfId:this.ariaWfId,
    dateFrom:this.dateFrom,
    dateTo: this.dateTo
  };
  constructor(
      private _ariaWfService: AriaWfService,
      private _letterGenerationService: LettersGenerationService,
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.requestParams.page = 0;
    this._getListData();
  }

  ngOnChanges(changes: SimpleChanges) {
    const dates = TimeTools.alignUTCDatesFromTo(this.dateFrom, this.dateTo);

    if(!dates) {
      return;
    }
    this.requestParams['status'] = this.statusFilter;
    this.requestParams['awaitingCreditMemo'] = this.awaitingCreditMemo;
    this.requestParams['letterType'] = this.letterType;
    this.requestParams['ariaWfId'] = this.ariaWfId;
    this.requestParams['dateFrom'] = dates.from;
    this.requestParams['dateTo'] = dates.to;

    this._getListData()
  }

  private _getListData() {

    if(this.missing) {
      this._letterGenerationService.getAriaItemVsAriaXmlMissingDetails(this.requestParams).subscribe( (response:ResponseList<AriaWfLetterItem>) => {
        this.dataSource = response.data;
        this.totalCount = response.total;
        this._cd.detectChanges();
      });
    } else {
      this._ariaWfService.getLettersItems(this.requestParams).subscribe( (response:ResponseList<AriaWfLetterItem>) => {
        this.dataSource = response.data;
        this.totalCount = response.total;
        this._cd.detectChanges();
      });
    }



  }

  public pageChange(event: any) {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this._getListData();
  }

  public sortData(sort:Sort) {
    this.requestParams.sort = sort.active;
    this.requestParams.order = sort.direction;
    this.requestParams.page = 0;
    this._getListData();
  }

}

