<table
        mat-table
        [dataSource]="dataSource" matSort matSortActive="executionTime" matSortDisableClear matSortDirection="desc"
        (matSortChange)="sortData($event)">

    <ng-container matColumnDef="executionTime">
        <th mat-header-cell mat-sort-header="executionTime" *matHeaderCellDef class="u-th-center">Execution Time [UTC]</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.executionTime | date: 'dd MMM y, HH:mm:ss':'UTC' }}</td>
    </ng-container>

    <ng-container matColumnDef="ariaWfLetterId">
        <th mat-header-cell mat-sort-header="ariaWfId" *matHeaderCellDef class="u-th-center">Aria WF Id</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.ariaWfId}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header="status" *matHeaderCellDef class="u-th-center">Status</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
            <span class="u-status-box u-status-box--wider-l"
                  [class.u-status-box--ok]="row.status === 'processed'"
                  [class.u-status-box--error]="row.status === 'failed'"
                  [class.u-status-box--orange]="row.status === 'RemovedByProcessNETSFile'"
                  [class.u-status-box--blue]="row.status === 'processing'"
            >{{row.status | araiWfLetterItemStatus}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="letterType">
        <th mat-header-cell mat-sort-header="letterType" *matHeaderCellDef class="u-th-center">Letter Type</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
             <span class="u-status-box u-status-box--wider-l"
                   [class.u-status-box--ok]="row.letterType === 'CardRegistration'"
                   [class.u-status-box--warn]="row.letterType === 'CreditMemo'"
                   [class.u-status-box--error]="row.letterType === 'Dunning'"
                   [class.u-status-box--yellow]="row.letterType === 'Information'"
                   [class.u-status-box--orange]="(row.letterType === 'Invoice' || row.letterType === 'invoice')"
                   [class.u-status-box--blue]="row.letterType === 'PbsRegistration'"
                   [class.u-status-box--purple]="row.letterType === 'ReactivatePlanLetter'"
                   [class.u-status-box--pink]="row.letterType === 'refundInformation'"
                   [class.u-status-box--cyan]="row.letterType === 'Renewal'"
             >{{row.letterType | transformLetterName}}</span>
        </td>


    </ng-container>

    <ng-container matColumnDef="invoiceNo">
        <th mat-header-cell mat-sort-header="invoiceNumber" *matHeaderCellDef class="u-th-center">Invoice No</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{(row.hasInvoice)?row.invoiceNumber:'-'}}</td>
    </ng-container>

    <ng-container matColumnDef="billingGroupId">
        <th mat-header-cell mat-sort-header="billingGroupId" *matHeaderCellDef class="u-th-center">Billing Group Id</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.billingGroupId}}</td>
    </ng-container>

    <ng-container matColumnDef="salesForceCustomerId">
        <th mat-header-cell mat-sort-header="sfCustomerId" *matHeaderCellDef class="u-th-center">SF Customer Id</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.sfCustomerId}}</td>
    </ng-container>

    <ng-container matColumnDef="scenarioCode">
        <th mat-header-cell mat-sort-header="scenarioCode" *matHeaderCellDef class="u-th-center">Scenario</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.scenarioCode}} / {{row.subScenarioCode}}</td>
    </ng-container>

    <ng-container matColumnDef="creditMemo">
        <th mat-header-cell mat-sort-header="is_waiting_for_credit_memo" *matHeaderCellDef class="u-th-center">Credit Memo</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
            <mat-icon color="warn" *ngIf="row.is_waiting_for_credit_memo">done</mat-icon>
            <mat-icon class="u-color-green"  *ngIf="!row.is_waiting_for_credit_memo">close</mat-icon>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="lead-row" *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<ng-container *ngIf="totalCount <= 0">
    <div class="no-data">
        <p>IT LOOKS THERE IS NO DATA ... </p>
    </div>

</ng-container>


<mat-paginator
        *ngIf="totalCount > 0"
        [length]="totalCount"
        color="accent"
        [pageSize]="requestParams.limit"
        [pageIndex]="requestParams.page"
        [showFirstLastButtons]="true"
        (page)="pageChange($event)"
></mat-paginator>
