import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-monitors-notifications-main',
  templateUrl: './monitors-notifications-main.component.html',
  styleUrls: ['./monitors-notifications-main.component.scss']
})
export class MonitorsNotificationsMainComponent implements OnInit {
  public tableTypeFilter = '';
  public tableStatusFilter = '';
  public tableInfoFilter = '';

  public dateRange!:FormGroup;

  ngOnInit() {
    const start = DateTime.now().toUTC().minus({day: 7}).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISO();
    const end = DateTime.now().toUTC().set({hour: 23, minute: 59, second: 59, millisecond: 999}).toISO();

    this.dateRange = new FormGroup({
      start: new FormControl<Date | string | null>(start),
      end: new FormControl<Date | string | null>(end)
    })
  }

  public clearMainFilters() {
    this.dateRange.get('start')?.setValue('');
    this.dateRange.get('end')?.setValue('');
  }

  public clearTableFilters() {
  this.tableTypeFilter = '';
  this.tableStatusFilter = '';
  this.tableInfoFilter = '';
  }

}
