import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges, SimpleChanges,
  ViewChild
} from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartItem, ChartOptions, registerables } from 'chart.js/auto';

@Component({
  selector: 'app-line-chart-three-types',
  templateUrl: './line-chart-three-types.component.html',
  styleUrls: ['./line-chart-three-types.component.scss']
})
export class LineChartThreeTypesComponent implements AfterViewInit, OnChanges{
  @ViewChild('chartLineThreeTypesCanvas') charCanvas?: ElementRef;
  @Input() charData!:ChartData;
  @Input() chartOptions!:ChartOptions;

  public chart!:Chart;
  private _chartCreated:boolean = false;

  constructor(
      private _cd:ChangeDetectorRef
  ) {
    Chart.register(...registerables);
  }

  ngAfterViewInit() {
    this._createChart();
    this._cd.detectChanges();
    this.chart?.resize()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this._chartCreated) {
      this.chart.data = this.charData;
      this.chart.update();
    }
  }

  private _createChart() {
    const chartItem: ChartItem = this.charCanvas?.nativeElement;
    const chartConfig:ChartConfiguration= {
      type: 'line',
      data: this.charData,
      options: this.chartOptions
    };

    this.chart = new Chart(chartItem, chartConfig);
    this._chartCreated = true;
    this._cd.detectChanges();
  }
}
