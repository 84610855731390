import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RequestParams } from '../../../models/list';
import { Observable } from 'rxjs';
import { ResponseList } from '../../../models/response.model';
import { NetaxeptTransaction } from '../../../models/netaxept-transaction.model';
import { TransformMonitor } from '../../../models/transform-monitor.model';

@Injectable({
  providedIn: 'root'
})
export class TransformService {

  private _apiUrl: string = environment.apiUrl;

  constructor(
      private _http:HttpClient
  ) { }

  public getMonitors(params:RequestParams):Observable<ResponseList<TransformMonitor>> {
    return this._http.get<ResponseList<TransformMonitor>>(`${this._apiUrl}/transform/monitors`, {params});
  }
}
