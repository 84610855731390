import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { User, UserDto } from '../../../../models/user.model';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit{

  public userForm!:FormGroup;
  public user!:User;

  constructor(
      private _formBuilder:FormBuilder,
      private _usersService:UsersService,
      private _toast:ToastService,
      public dialogRef: MatDialogRef<UserEditDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public userId: string,
  ) { }

  ngOnInit() {
    this._creatForm();
    this._usersService.getUser(this.userId).subscribe( data => {
        this.user = data.data;
        this._creatForm();
    })

  }

  private _creatForm() {
    this.userForm = this._formBuilder.group({
      name: new FormControl(this.user?.name || '', [Validators.required, Validators.minLength(3)]),
      surname: new FormControl(this.user?.surname || '', [Validators.required, Validators.minLength(3)]),
      username: new FormControl(this.user?.username || '', [Validators.required, Validators.minLength(3)]),
      email: new FormControl(this.user?.email || '', [Validators.required, Validators.email]),
      role: new FormControl(this.user?.role || 'U', [Validators.required]),
      active: new FormControl(this.user?.active || false, [Validators.required]),
    });
  }


  public onCancel() {
    this.dialogRef.close(false);
  }

  public onSubmit() {
    const user:UserDto = this.userForm.getRawValue();
    this._usersService.edit(this.userId, user).subscribe((data)=> {
      this.dialogRef.close(true);
    }, error => {
        this._toast.error(error.error.message);
    })

  }

}
