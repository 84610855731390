<div class="container">
    <mat-toolbar color="primary" class="header">
        <h1>Letters Generation</h1>
        <div class="filters">
            <mat-form-field class="small-date-range" color="accent">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input  [formGroup]="dateRange" [rangePicker]="picker">
                    <input formControlName="start" matStartDate placeholder="Start date">
                    <input formControlName="end" matEndDate placeholder="End date" (dateChange)="datesChanged()">
                </mat-date-range-input>
                <mat-datepicker-toggle class="small-date-range__date-picker" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <button mat-icon-button color="accent" aria-label="Clear filters" (click)="clearMainFilters()"
                    matTooltip="Clear date filters">
                <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            </button>

        </div>
    </mat-toolbar>

    <main class="content">

        <!-- GAUGE -->
        <mat-card class="min-height-20vw">
            <mat-card-header>
                <mat-card-title class="header-title">Overall missing letters in the Aria XML Table</mat-card-title>
                <mat-card-subtitle>The difference between stored letters in Aria Letter Item Table and Aria XML Table in the provided period</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="card-content">
                    <app-missing-letters-gauge
                            [statsData]="missingItemVsXml"
                    ></app-missing-letters-gauge>
            </mat-card-content>
        </mat-card>

        <!-- CHART -->
        <mat-card class="min-height-20vw">
            <mat-card-header>
                <mat-card-title class="header-title">Missing letters in the Aria XML Table by letter type</mat-card-title>
                <mat-card-subtitle>The difference between stored letters in Aria Letter Item Table and Aria XML Table in the provided period</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-missing-letters-types-chart
                        [statsData]="missingItemVsXmlByType"
                ></app-missing-letters-types-chart>
            </mat-card-content>
        </mat-card>

        <!-- GAUGE -->
        <mat-card class="min-height-20vw">
            <mat-card-header>
                <mat-card-title class="header-title">Overall missing letters in the Transform</mat-card-title>
                <mat-card-subtitle>The difference between stored letters in Aria XML Table and Transform in the provided period</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-missing-letters-gauge
                        [statsData]="missingXmlVsTransform"
                ></app-missing-letters-gauge>
            </mat-card-content>
        </mat-card>

        <!-- CHART -->
        <mat-card class="min-height-20vw">
            <mat-card-header>
                <mat-card-title class="header-title">Missing letters in the Transform by letter type</mat-card-title>
                <mat-card-subtitle>The difference between stored letters in Aria XML Table and Transform in the provided period</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <app-missing-letters-types-chart
                        [statsData]="missingXmlVsTransformByType"
                ></app-missing-letters-types-chart>
            </mat-card-content>
        </mat-card>

        <!-- TABLE -->
        <mat-card class="content__two-column">
            <mat-card-header>
                <mat-card-title class="header-title">Missing letters in Aria WF Letter XML's</mat-card-title>
                <mat-card-subtitle>Found in Aria WF Letter Item but missed in Letter XML's</mat-card-subtitle>
                <div class="filters">
<!--                    <mat-form-field class="small-input" color="accent">-->
<!--                        <mat-label>Aria WF Id</mat-label>-->
<!--                        <input matInput [placeholder]="'Aria WF Id'" [(ngModel)]="ariaWfLetterItemTableMissingAriaWfIdFilter" type="text">-->
<!--                    </mat-form-field>-->


                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Letter Type</mat-label>
                        <mat-select [placeholder]="'Letter Type'"[(ngModel)]="ariaWfLetterItemTableLetterMissingTypeFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="CardRegistration">CARD REGISTRATION</mat-option>
                            <mat-option value="CreditMemo">CREDIT MEMO</mat-option>
                            <mat-option value="Dunning">DUNNING</mat-option>
                            <mat-option value="Information">INFORMATION</mat-option>
                            <mat-option value="Invoice">INVOICE</mat-option>
                            <mat-option value="PbsRegistration">PBS REGISTRATION</mat-option>
                            <mat-option value="ReactivatePlanLetter">REACTIVATE PLAN LETTER</mat-option>
                            <mat-option value="refundInformation">REFUND INFORMATION</mat-option>
                            <mat-option value="Renewal">RENEWAL</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button matTooltip="Download CSV" color="accent" (click)="getMissingItemsCSV()"><mat-icon class="btn-icon">description</mat-icon></button>

<!--                    <mat-form-field class="small-select" color="accent">-->
<!--                        <mat-label>Status</mat-label>-->
<!--                        <mat-select [placeholder]="'Status'"[(ngModel)]="ariaWfLetterItemTableMissingStatusFilter">-->
<!--                            <mat-option value=""> &#45;&#45; ALL &#45;&#45; </mat-option>-->
<!--                            <mat-option value="new">NEW</mat-option>-->
<!--                            <mat-option value="processing">PROCESSING</mat-option>-->
<!--                            <mat-option value="processed">PROCESSED</mat-option>-->
<!--                            <mat-option value="failed">FAILED</mat-option>-->
<!--                            <mat-option value="RemovedByProcessNETSFile">REMOVED BY NETS FILE</mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
                </div>
            </mat-card-header>
            <mat-card-content>

                <app-aria-wf-letter-items-table
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [statusFilter]="ariaWfLetterItemTableMissingStatusFilter"
                        [letterType]="ariaWfLetterItemTableLetterMissingTypeFilter"
                        [ariaWfId]="ariaWfLetterItemTableMissingAriaWfIdFilter"
                        [displayedColumns]="['executionTime', 'ariaWfLetterId', 'status','letterType']"
                        [missing]="true"
                ></app-aria-wf-letter-items-table>

            </mat-card-content>
        </mat-card>

        <!-- TABLE -->
        <mat-card class="content__two-column">
            <mat-card-header>
                <mat-card-title class="header-title">Missing letters in Transform</mat-card-title>
                <mat-card-subtitle>Found in Aria WF Letter XML's but missed in Transform</mat-card-subtitle>
                <div class="filters">
<!--                    <mat-form-field class="small-input" color="accent">-->
<!--                        <mat-label>Aria WF Id</mat-label>-->
<!--                        <input matInput [placeholder]="'Aria WF Id'" [(ngModel)]="ariaWfLetterXmlTableMissingAriaWfIdFilter" type="text">-->
<!--                    </mat-form-field>-->


                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Letter Type</mat-label>
                        <mat-select [placeholder]="'Letter Type'"[(ngModel)]="ariaWfLetterXmlTableMissingLetterTypeFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="CardRegistration">CARD REGISTRATION</mat-option>
                            <mat-option value="CreditMemo">CREDIT MEMO</mat-option>
                            <mat-option value="Dunning">DUNNING</mat-option>
                            <mat-option value="Information">INFORMATION</mat-option>
                            <mat-option value="Invoice">INVOICE</mat-option>
                            <mat-option value="PbsRegistration">PBS REGISTRATION</mat-option>
                            <mat-option value="ReactivatePlanLetter">REACTIVATE PLAN LETTER</mat-option>
                            <mat-option value="refundInformation">REFUND INFORMATION</mat-option>
                            <mat-option value="Renewal">RENEWAL</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button matTooltip="Download CSV" color="accent" (click)="getMissingXmlCSV()"><mat-icon class="btn-icon">description</mat-icon></button>

<!--                    <mat-form-field class="small-select" color="accent">-->
<!--                        <mat-label>Status</mat-label>-->
<!--                        <mat-select [placeholder]="'Status'"[(ngModel)]="ariaWfLetterXmlTableMissingStatusFilter">-->
<!--                            <mat-option value=""> &#45;&#45; ALL &#45;&#45; </mat-option>-->
<!--                            <mat-option value="new">NEW</mat-option>-->
<!--                            <mat-option value="processing">PROCESSING</mat-option>-->
<!--                            <mat-option value="processed">PROCESSED</mat-option>-->
<!--                            <mat-option value="failed">FAILED</mat-option>-->
<!--                            <mat-option value="RemovedByProcessNETSFile">REMOVED BY NETS FILE</mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->

                </div>
            </mat-card-header>
            <mat-card-content>
                <app-aria-wf-letter-xmls-table
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [statusFilter]="ariaWfLetterXmlTableMissingStatusFilter"
                        [letterType]="ariaWfLetterXmlTableMissingLetterTypeFilter"
                        [ariaWfId]="ariaWfLetterXmlTableMissingAriaWfIdFilter"
                        [displayedColumns]="['createDate', 'ariaWfLetterId', 'status', 'letterType', 'boomiError']"
                        [missing]="true"

                >

                </app-aria-wf-letter-xmls-table>
            </mat-card-content>
        </mat-card>

        <!-- TABLE -->
        <mat-card class="content__one-column">
            <mat-card-header>
                <mat-card-title class="header-title">Aria WF Letter Items</mat-card-title>
                <mat-card-subtitle>Detailed information from Aria WF Letter Items </mat-card-subtitle>
                <div class="filters">
                    <mat-form-field class="small-input" color="accent">
                        <mat-label>Aria WF Id</mat-label>
                        <input matInput [placeholder]="'Aria WF Id'" [(ngModel)]="ariaWfLetterItemTableAriaWfIdFilter" type="text">
                    </mat-form-field>


                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Letter Type</mat-label>
                        <mat-select [placeholder]="'Letter Type'"[(ngModel)]="ariaWfLetterItemTableLetterTypeFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="CardRegistration">CARD REGISTRATION</mat-option>
                            <mat-option value="CreditMemo">CREDIT MEMO</mat-option>
                            <mat-option value="Dunning">DUNNING</mat-option>
                            <mat-option value="Information">INFORMATION</mat-option>
                            <mat-option value="Invoice">INVOICE</mat-option>
                            <mat-option value="PbsRegistration">PBS REGISTRATION</mat-option>
                            <mat-option value="ReactivatePlanLetter">REACTIVATE PLAN LETTER</mat-option>
                            <mat-option value="refundInformation">REFUND INFORMATION</mat-option>
                            <mat-option value="Renewal">RENEWAL</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Status</mat-label>
                        <mat-select [placeholder]="'Status'"[(ngModel)]="ariaWfLetterItemTableStatusFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="new">NEW</mat-option>
                            <mat-option value="processing">PROCESSING</mat-option>
                            <mat-option value="processed">PROCESSED</mat-option>
                            <mat-option value="failed">FAILED</mat-option>
                            <mat-option value="RemovedByProcessNETSFile">REMOVED BY NETS FILE</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Awaiting Credit Memo</mat-label>
                        <mat-select [placeholder]="'Awaiting Credit Memo<'"[(ngModel)]="ariaWfLetterItemTableAwaitingCreditMemoFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="true">TRUE</mat-option>
                            <mat-option value="false">FALSE</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-header>
            <mat-card-content>
                <app-aria-wf-letter-items-table
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [statusFilter]="ariaWfLetterItemTableStatusFilter"
                        [awaitingCreditMemo]="ariaWfLetterItemTableAwaitingCreditMemoFilter"
                        [letterType]="ariaWfLetterItemTableLetterTypeFilter"
                        [ariaWfId]="ariaWfLetterItemTableAriaWfIdFilter"
                ></app-aria-wf-letter-items-table>
            </mat-card-content>
        </mat-card>

        <!-- TABLE -->
        <mat-card class="content__one-column">
            <mat-card-header>
                <mat-card-title class="header-title">Aria WF Letter XML's</mat-card-title>
                <mat-card-subtitle>Detailed information from Aria WF Letter XML's </mat-card-subtitle>
                <div class="filters">
                    <mat-form-field class="small-input" color="accent">
                        <mat-label>Aria WF Id</mat-label>
                        <input matInput [placeholder]="'Aria WF Id'" [(ngModel)]="ariaWfLetterXmlTableAriaWfIdFilter" type="text">
                    </mat-form-field>


                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Letter Type</mat-label>
                        <mat-select [placeholder]="'Letter Type'"[(ngModel)]="ariaWfLetterXmlTableLetterTypeFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="CardRegistration">CARD REGISTRATION</mat-option>
                            <mat-option value="CreditMemo">CREDIT MEMO</mat-option>
                            <mat-option value="Dunning">DUNNING</mat-option>
                            <mat-option value="Information">INFORMATION</mat-option>
                            <mat-option value="Invoice">INVOICE</mat-option>
                            <mat-option value="PbsRegistration">PBS REGISTRATION</mat-option>
                            <mat-option value="ReactivatePlanLetter">REACTIVATE PLAN LETTER</mat-option>
                            <mat-option value="refundInformation">REFUND INFORMATION</mat-option>
                            <mat-option value="Renewal">RENEWAL</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Status</mat-label>
                        <mat-select [placeholder]="'Status'"[(ngModel)]="ariaWfLetterXmlTableStatusFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="new">NEW</mat-option>
                            <mat-option value="processing">PROCESSING</mat-option>
                            <mat-option value="processed">PROCESSED</mat-option>
                            <mat-option value="failed">FAILED</mat-option>
                            <mat-option value="RemovedByProcessNETSFile">REMOVED BY NETS FILE</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
            </mat-card-header>
            <mat-card-content>
               <app-aria-wf-letter-xmls-table
                       [dateFrom]="dateRange.getRawValue().start"
                       [dateTo]="dateRange.getRawValue().end"
                       [statusFilter]="ariaWfLetterXmlTableStatusFilter"
                       [letterType]="ariaWfLetterXmlTableLetterTypeFilter"
                       [ariaWfId]="ariaWfLetterXmlTableAriaWfIdFilter">

               </app-aria-wf-letter-xmls-table>
            </mat-card-content>
        </mat-card>

        <!-- TABLE -->
        <mat-card class="content__one-column">
            <mat-card-header>
                <mat-card-title class="header-title">Transform Monitors</mat-card-title>
                <mat-card-subtitle>Detailed information from Transform Monitors</mat-card-subtitle>
                <div class="filters">

                    <mat-form-field class="small-input" color="accent">
                        <mat-label>Aria WF Id</mat-label>
                        <input matInput [placeholder]="'Aria WF Id'" [(ngModel)]="monitorTableAriaWfIdFilter" type="text">
                    </mat-form-field>


                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Letter Type</mat-label>
                        <mat-select [placeholder]="'Letter Type'"[(ngModel)]="monitorTableLetterTypeFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="CardRegistration">CARD REGISTRATION</mat-option>
                            <mat-option value="CreditMemo">CREDIT MEMO</mat-option>
                            <mat-option value="Dunning">DUNNING</mat-option>
                            <mat-option value="Information">INFORMATION</mat-option>
                            <mat-option value="Invoice">INVOICE</mat-option>
                            <mat-option value="PbsRegistration">PBS REGISTRATION</mat-option>
                            <mat-option value="ReactivatePlanLetter">REACTIVATE PLAN LETTER</mat-option>
                            <mat-option value="refundInformation">REFUND INFORMATION</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Status</mat-label>
                        <mat-select [placeholder]="'Status'"[(ngModel)]="monitorTableStatusFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="Success">SUCCESS</mat-option>
                            <mat-option value="Error">ERROR</mat-option>
                        </mat-select>
                    </mat-form-field>

<!--                    <mat-form-field class="small-select" color="accent">-->
<!--                        <mat-label>State</mat-label>-->
<!--                        <mat-select [placeholder]="'State'"[(ngModel)]="monitorTableStateFilter">-->
<!--                            <mat-option value=""> -- ALL -- </mat-option>-->
<!--                            <mat-option value="Ingested">INGESTED</mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->

                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Awaiting Credit Memo</mat-label>
                        <mat-select [placeholder]="'Awaiting Credit Memo<'"[(ngModel)]="monitorTableAwaitingCreditMemoFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="true">TRUE</mat-option>
                            <mat-option value="false">FALSE</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button color="accent" aria-label="Clear filters" (click)="clearMonitorsTableFilters()"
                            matTooltip="Clear all filters">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <mat-card-content>
                <app-monitors-table
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [statusFilter]="monitorTableStatusFilter"
                        [stateFilter]="monitorTableStateFilter"
                        [awaitingCreditMemo]="monitorTableAwaitingCreditMemoFilter"
                        [letterType]="monitorTableLetterTypeFilter"
                        [ariaWfId]="monitorTableAriaWfIdFilter"
                ></app-monitors-table>
            </mat-card-content>
        </mat-card>



    </main>


</div>
