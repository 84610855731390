import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NetaxeptErrorCode, NetaxeptErrorCodeByDate } from '../../../../models/netaxept-error.model';
import { PAGE_SIZE_OPTIONS, RequestParams, SortDirections } from '../../../../models/list';
import { NetaxeptService } from '../../services/netaxept.service';
import { ToastService } from '../../../shared/services/toast.service';
import { ResponseList } from '../../../../models/response.model';
import { Sort } from '@angular/material/sort';
import { NetaxeptErrorCodeInfo } from '../../../../models/netaxept-transaction.model';

@Component({
  selector: 'app-error-codes-table',
  templateUrl: './error-codes-table.component.html',
  styleUrls: ['./error-codes-table.component.scss']
})
export class ErrorCodesTableComponent implements OnInit {

  public displayedColumns: string[] = ['code', 'message', 'source', 'first', 'last'];
  public dataSource: NetaxeptErrorCodeByDate[] = [];
  public totalCount = 0;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;
  public requestParams: RequestParams = {
    page: 0,
    limit: 30,
    sort: 'firstTimeShowUp',
    order: SortDirections.DESC
  }

  constructor(
      private _netaxeptService:NetaxeptService,
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this._getListData();
  }

  private _getListData() {
    this._netaxeptService.getErrorCodes(this.requestParams).subscribe( (response:ResponseList<NetaxeptErrorCodeByDate>) => {
      this.dataSource = response.data;
      this.totalCount = response.total;
      this._cd.detectChanges();
    });
  }

  public pageChange(event: any) {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this._getListData();
  }

  public sortData(sort:Sort) {
    this.requestParams.sort = sort.active;
    this.requestParams.order = sort.direction;
    this.requestParams.page = 0;
    this._getListData();
  }

  public getErrorAdditionalInfo(errorCode:string) {
    return NetaxeptErrorCodeInfo[`CODE_${errorCode}`];
  }
}
