<div class="container">
    <h3>Create user</h3>

    <form [formGroup]="userForm" autocomplete="off">
        <div class="form-inputs">
            <mat-form-field color="primary" >
                <mat-label>First name</mat-label>
                <input matInput type="text" placeholder="First name" formControlName="name" autocomplete="off"/>
            </mat-form-field>

            <mat-form-field color="primary" >
                <mat-label>Last name</mat-label>
                <input matInput type="text" placeholder="Last name" formControlName="surname" autocomplete="off"/>
            </mat-form-field>

            <mat-form-field color="primary" >
                <mat-label>Username</mat-label>
                <input matInput type="text" placeholder="Username" formControlName="username" autocomplete="off"/>
            </mat-form-field>

            <mat-form-field color="primary" >
                <mat-label>Email</mat-label>
                <input matInput type="email" placeholder="Email" formControlName="email" autocomplete="off"/>
            </mat-form-field>

            <mat-form-field appearance="outline" color="primary" >
                <mat-label>Role</mat-label>
                <mat-select formControlName="role" [value]="'U'">
                    <mat-option [value]="'U'">User</mat-option>
                    <mat-option [value]="'A'">Administrator</mat-option>
                    <mat-option [value]="'S'">Super Administrator</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" color="primary" >
                <mat-label>Active</mat-label>
                <mat-select formControlName="active" [value]="false">
                    <mat-option [value]="false">False</mat-option>
                    <mat-option [value]="true">True</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="action">
            <button mat-flat-button color="primary" (click)="onCancel()">Cancel</button>
            <button mat-flat-button color="accent" (click)="onSubmit()">Update</button>
        </div>
    </form>
</div>
