<div class="container">
    <mat-toolbar color="primary" class="header">
        <h1>Monitors Notifications</h1>
        <div class="filters">
            <mat-form-field class="small-date-range" color="accent">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input  [formGroup]="dateRange" [rangePicker]="picker">
                    <input formControlName="start" matStartDate placeholder="Start date">
                    <input formControlName="end" matEndDate placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle class="small-date-range__date-picker" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
            <button mat-icon-button color="accent" aria-label="Clear filters" (click)="clearMainFilters()"
                    matTooltip="Clear date filters">
                <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <main class="content">

        <mat-card class="content__one-column">
            <mat-card-header>
                <mat-card-title class="header-title">All Monitors Notifications</mat-card-title>
                <mat-card-subtitle>Detailed information</mat-card-subtitle>
                <div class="filters">

                    <mat-form-field class="small-input" color="accent">
                        <mat-label>Info search</mat-label>
                        <input matInput [placeholder]="'Type something...'"  [(ngModel)]="tableInfoFilter" type="text">
                    </mat-form-field>


                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Status</mat-label>
                        <mat-select [placeholder]="'Status'"[(ngModel)]="tableStatusFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="SENT">SENT</mat-option>
                            <mat-option value="TO SEND">TO SEND</mat-option>
                            <mat-option value="ERROR">ERROR</mat-option>


                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="small-select" color="accent">
                        <mat-label>Type</mat-label>
                        <mat-select [placeholder]="'-- ALL --'" [(ngModel)]="tableTypeFilter">
                            <mat-option value=""> -- ALL -- </mat-option>
                            <mat-option value="WARNING">WARNING</mat-option>
                            <mat-option value="ERROR">ERROR</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button color="accent" aria-label="Clear filters" (click)="clearTableFilters()"
                            matTooltip="Clear all filters">
                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <mat-card-content>
                <app-monitors-notifications-table
                        [dateFrom]="dateRange.getRawValue().start"
                        [dateTo]="dateRange.getRawValue().end"
                        [status]="tableStatusFilter"
                        [type]="tableTypeFilter"
                        [info]="tableInfoFilter"
                ></app-monitors-notifications-table>
            </mat-card-content>
        </mat-card>
    </main>
</div>
