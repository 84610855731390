import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationItem } from '../../../../models/navigation.model';
import { APP_META } from '../../../../../version';
import { AuthService } from '../../../auth/services/auth.service';
import { User } from '../../../../models/user.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit{

  public version = APP_META.version
  public user?:User;


  @Output() menuSelected:EventEmitter<void> = new EventEmitter();

  constructor(
      private authService:AuthService
  ) {
    this.version = APP_META.version;

  }

  ngOnInit() {
    const session = this.authService.session.getValue();
    this.user = session.user;
  }

  public onMenuClick() {
    this.menuSelected.emit();
  }

  public getInitials():string {
    if(this.user) {
      return this.user?.name.substr(0,1).toString() + this.user?.surname.substr(0,1).toString();
    } else {
      return 'XX'
    }

  }
}
