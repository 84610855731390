import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './modules/core/core.module';
import { AuthModule } from './modules/auth/auth.module';
import { SharedModule } from './modules/shared/shared.module';
import { HotToastModule } from '@ngneat/hot-toast';
import { AuthInterceptorService } from './modules/auth/services/auth-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UsersModule } from './modules/users/users.module';
import { NetaxeptModule } from './modules/netaxept/netaxept.module';
import { HealthMetricsModule } from './modules/health-metrics/health-metrics.module';
import { LettersGenerationModule } from './modules/letters-generation/letters-generation.module';
import { MonitorsNotificationsModule } from './modules/monitors-notifications/monitors-notifications.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    CoreModule,
    UsersModule,
    SharedModule,
    HotToastModule.forRoot({
      theme: 'snackbar',
      position: 'bottom-center'
    }),
    HealthMetricsModule,
    NetaxeptModule,
    LettersGenerationModule,
    MonitorsNotificationsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
