<div class="container">

    <div class="container__content card">
        <div>


        <div class="logo">
            <img src="assets/flack-logo.png" alt="Flack A/S logo"/>
            <span>CTS Monitoring</span>
        </div>
        <div class="name">

        </div>

        <div>

            <form name="login-from" [formGroup]="loginForm" novalidate autocomplete="off" (ngSubmit)="login()" class="login-form">
                <mat-form-field color="primary">
                    <mat-label>Username</mat-label>
                    <input
                            matInput
                            autocomplete="off"
                            id="username"
                            name="username"
                            placeholder="Username"
                            formControlName="username"
                            required
                    />
                </mat-form-field>


                <mat-form-field color="primary">
                    <mat-label>Password</mat-label>
                    <input
                            matInput
                            autocomplete="off"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            formControlName="password"
                            required
                    />
                </mat-form-field>

                <div class="forgot-password">
                    <a class="forgot-password__link" routerLink="/auth/forgot-password">
                        * Forgot password?
                    </a>
                </div>

                <button
                        mat-flat-button
                        color="primary"
                        class="submit-button"
                        type="submit"
                        [disabled]="loginForm.invalid"
                >SIGN IN
                </button>

                <div class="footer">
                    <span><strong>v{{version}}</strong></span>
                    <span>powered by <a href="mailto:piotr.skolysz@falck.com" target="_blank" rel="noopener nofollow noreferrer">Piotr Skołysz</a></span>
                </div>

            </form>
        </div>
        </div>
    </div>


</div>
