import { NetaxeptError } from './netaxept-error.model';

export interface NetaxeptTransaction {
  _id: string;
  transactionId: string;
  status: NetaxeptTransactionStatus;
  orderNumber: string;
  description: string;
  amount:number;
  currency: string;
  transactionReference: string | null;
  authorizationId: string | null;
  capturedAmount: number;
  creditedAmount: number;
  totalCapturedAmount: number;
  isAnnul: boolean;
  isAuthedOrSaled: boolean;
  issuer: string;
  issuerCountry: string;
  timestamp: Date;
  captureDescription: string | null
  errorDetails?: NetaxeptError[];
  createdAt: Date;
  updatedAt: Date;

}


export type NetaxeptTransactionStatus = 'OK' | 'WARNING' | 'ERROR';
export enum NetaxeptTransactionStatuses {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}
export const NetaxeptErrorCodeInfo:any = {
   CODE_01:  "Refer to card issuer",
   CODE_02:  "Refer to card issuer’s special conditions",
   CODE_03:  "Invalid merchant or service provider",
   CODE_04:  "Pick-up", // Capture card
   CODE_05:  "Do not honor(Nets DK, EVRY, Elavon)",
   CODE_06:  "Error",
   CODE_07:  "Pick-up card, special condition (other than lost / stolen card)",
   CODE_08:  "Honour with identification",
   CODE_09:  "Request in progress",
   CODE_10:  "Approved for partial amount",
   CODE_11:  "Approved (V.I.P.)", // For security reasons, this CODE_should not be used by the issuer. If CODE_11 is received at the VIC, it is changed to 00 before the  is ed to the acquirer.
   CODE_12:  "Invalid transaction",
   CODE_13:  "Invalid amount (currency conversion field overflow), or amount exceeds maximum for card program",
   CODE_14:  "Invalid card number (no such number)",
   CODE_15:  "No such issuer",
   CODE_16:  "Approved, update track 3",
   CODE_17:  "Customer cancellation",
   CODE_18:  "Customer dispute",
   CODE_19:  "Re-enter transaction",
   CODE_20:  "Invalid ",
   CODE_21:  "No action taken (unable to back out prior transaction)",
   CODE_22:  "Suspected malfunction",
   CODE_23:  "Unacceptable transaction fee",
   CODE_24:  "File update not supported by receiver",
   CODE_25:  "Unable to locate record on file",
   CODE_26:  "Duplicate file update record, old record replaced. Record not in active status",
   CODE_27:  "File update field edit error. Issuer File Update field edit error",
   CODE_28:  "File update field locked out. Record permanently deleted. VI:File is temporarily unavailable",
   CODE_29:  "File update not successful, contact acquirer. Delete request less than 540 days",
   CODE_30:  "Format error",
   CODE_31:  "Bank not supported by switch,MC:Issuer sign-off",
   CODE_32:  "Completed partially",
   CODE_33:  "Expired card",
   CODE_34:  "Suspected fraud,SO:Wrong check digit",
   CODE_35:  "Card acceptor contact acquirer",
   CODE_36:  "Restricted card",
   CODE_37:  "Card acceptor call acquirer security",
   CODE_38:  "Allowable PIN tries exceeded",
   CODE_39:  "No credit account",
   CODE_40:  "Requested function not supported,SO:Unknown processing code",
   CODE_41:  "Lost card",
   CODE_42:  "No universal account",
   CODE_43:  "Stolen card, pick-up",
   CODE_44:  "No investment account",
   CODE_45:  "Reserved for ISO use ",
   CODE_46:  "Reserved for ISO use ",
   CODE_47:  "Reserved for ISO use ",
   CODE_48:  "Reserved for ISO use ",
   CODE_49:  "Reserved for ISO use ",
   CODE_50:  "Reserved for ISO use ",
   CODE_51:  "Not sufficient funds",
   CODE_52:  "No chequing account",
   CODE_53:  "No savings account",
   CODE_54:  "Expired card",
   CODE_55:  "Incorrect PIN",
   CODE_56:  "No card record",
   CODE_57:  "Trans not permitted to issuer/cardholder. VI: field 18 does not contain a merchant type for the transaction.",
   CODE_58:  "Trans not permitted to acquirer/terminal",
   CODE_59:  "Suspected fraud",
   CODE_60:  "Card acceptor contact acquirer",
   CODE_61:  "Exceeds withdrawal amount limit",
   CODE_62:  "Restricted card", //  CODE_62 applies to issuer-defined excluded or embargoed countries.
   CODE_63:  "Security violation",
   CODE_64:  "Original amount incorrect. Transaction does not fulfill AML requirement",
   CODE_65:  "MC:Strong Customer Authentication (SCA) Required / Exceeds withdrawal frequency limit", // so called "Soft-decline" for Mastercard is translated to error CODE_1A by STEPS, Full 3D-Secure is required by issuer
   CODE_66:  "Card acceptor call acquirer’s security department",
   CODE_67:  "Hard capture",
   CODE_68:  " received to late",
   CODE_69:  "Reserved for ISO use",
   CODE_70:  "Contact Card Issuer",
   CODE_71:  "PIN Not Changed",
   CODE_72:  "Reserved for ISO use ",
   CODE_73:  "Reserved for ISO use ",
   CODE_74:  "Reserved for ISO use",
   CODE_75:  "Allowable PIN tries exceeded",
   CODE_76:  "Invalid/nonexistent To Account specified. MC: Wrong PIN, allowable number of PIN tries exceeded. VI:Unable to locate previous message (no match on retrieval reference number)",
   CODE_77:  "Invalid/nonexistent From Account specified. VI: Previous message located for a repeat or reversal, but repeat or reversal data are inconsistent with original message,MC:MAC error",
   CODE_78:  "Invalid/nonexistent account specified(general) VI: Blocked, first used—Transaction from new cardholder, and card not properly unblocked",
   CODE_79:  "MC:Key Exchange Validation failed. VI:Transaction reversed",
   CODE_80:  "VI:Invalid date,MC:Duplicate add, action not performed, SO:Track 3 card not active",
   CODE_81:  "Domestic Debit Transaction Not Allowed (Regional use only). VI: Cryptographic error found in PIN or CVV,MC:Foreign network failure,SO:Fraud, card copied",
   CODE_82:  "MC:Time-out at IEM,SO:Wrong card format: VI:Negative Online CAM, dCVV, iCVV, or CVV results Or Offline PIN authentication interrupted",
   CODE_83:  "VI:Unable to verify PIN,MC:Transaction failed",
   CODE_84:  "Invalid Authorization Life Cycle",
   CODE_85:  "VI:No reason to decline a request for account verification or address verification", // Account Verification-0nly Request: Field 39 should be 85 (No Reason to Decline) or 00, if no negative condition is found.
   CODE_86:  "VI:Cannot verify PIN,MC:PIN validation not possible",
   CODE_87:  "Purchase Amount Only, No Cash Back Allowed",
   CODE_88:  "MC:Cryptographic failure,SO:PIN based card",
   CODE_89:  "MC:Unacceptable PIN—Transaction Declined—Retry, SO:Signature based card",
   CODE_90:  "Cutoff is in process",
   CODE_91:  "Issuer or switch is inoperative",
   CODE_92:  "Financial institution or intermediate network cannot be found for routing",
   CODE_93:  "Trans cannot be completed. Violation of law",
   CODE_94:  "Duplicate transmission. Duplicate SAF request",
   CODE_95:  "Reconcile error",
   CODE_96:  "System malfunction(Teller DK example: wrong MCC code)",
   CODE_97:  "Reserved for national use",
   CODE_98:  "SO:Transaction already processed",
   CODE_99:  "Reserved for national use",
   CODE_C9: "Reserved for national use",
   CODE_N0: "VI:Force STIP (Evry SE),MC:Internal error (Swedbank)",
   CODE_N7: "VI:Decline for CVV2 failure. The transaction would have been approved had the CVV2 value been allowed.",
   CODE_P1: "VI:Declined, PVID CODE_is missing, invalid or has expired",
   CODE_P9: "Reserved for private use",
   CODE_T3: "Reserved for national use",
   CODE_T8: "Reserved for national use",
   CODE_1A: "Issuer has soft declined the transaction. 3D Secure (or equivalent) authentication is required."
}
