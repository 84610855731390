<table
        mat-table
        [dataSource]="dataSource" matSort matSortActive="updatedAt" matSortDisableClear matSortDirection="desc"
        (matSortChange)="sortData($event)">

    <ng-container matColumnDef="updatedAt">
        <th mat-header-cell mat-sort-header="updatedAt" *matHeaderCellDef class="u-th-center">Date</th>
        <td style="min-width: 160px;" mat-cell *matCellDef="let row">{{row.updatedAt | date: 'dd MMM y, HH:mm:ss':'UTC' }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell mat-sort-header="type" *matHeaderCellDef class="u-th-center">Type</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
             <span class="u-status-box"
                   [class.u-status-box--warn]="row.type === 'WARNING'"
                   [class.u-status-box--error]="row.type === 'ERROR'"
             >
                {{row.type}}
            </span>

        </td>
    </ng-container>

    <ng-container matColumnDef="job">
        <th mat-header-cell mat-sort-header="job" *matHeaderCellDef class="u-th-center">Job</th>
        <td mat-cell *matCellDef="let row">{{formatJobName(row.facts[0].value)}}</td>
    </ng-container>

    <ng-container matColumnDef="title">
        <th mat-header-cell mat-sort-header="subtitle" *matHeaderCellDef class="u-th-center">Info</th>
        <td mat-cell *matCellDef="let row">
            <p>{{row.subtitle}}</p>
        </td>
    </ng-container>


    <ng-container matColumnDef="facts">
        <th mat-header-cell mat-sort-header="facts" *matHeaderCellDef class="u-th-center">Details</th>
        <td mat-cell *matCellDef="let row" [innerHTML]="row.facts | notificationsFacts"></td>
    </ng-container>


    <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header="status" *matHeaderCellDef class="u-th-center">Status</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
             <span class="u-status-box"
                   [class.u-status-box--ok]="row.status === 'SENT'"
                   [class.u-status-box--warn]="row.status === 'TO SEND'"
                   [class.u-status-box--error]="row.status === 'ERROR'"
             >
                {{row.status}}
            </span>

        </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="lead-row" *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<ng-container *ngIf="totalCount <= 0">
    <div class="no-data">
        <p>IT LOOKS THERE IS NO DATA ... </p>
    </div>

</ng-container>


<mat-paginator
        *ngIf="totalCount > 0"
        [length]="totalCount"
        color="accent"
        [pageSize]="requestParams.limit"
        [pageIndex]="requestParams.page"
        [showFirstLastButtons]="true"
        (page)="pageChange($event)"
></mat-paginator>
