import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Authenticate } from '../../../../models/authenticate.model';
import { ResponseSingle } from '../../../../models/response.model';
import { Auth } from '../../../../models/auth.model';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../../shared/services/toast.service';
import { HotToastService } from '@ngneat/hot-toast';
import { APP_META } from '../../../../../version';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {

  public loginForm!: FormGroup;
  public version = APP_META.version;

  private _state!: string | null;

  constructor(
      private _fb: FormBuilder,
      private _router: Router,
      private _route: ActivatedRoute,
      private _authService:AuthService,
      private _toastService: ToastService,
      private _toast: HotToastService
  ) {}


  ngOnInit(): void {
    this.loginForm = this._fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    if (this._route.snapshot?.queryParams['state']) {
      this._state = this._route.snapshot?.queryParams['state'];
    }
  }

  public login() {

    if (!this.loginForm.valid) {
      return;
    }
    const authenticate: Authenticate = {
      username: this.loginForm.getRawValue().username,
      password: this.loginForm.getRawValue().password,
    };

    const authObs: Observable<ResponseSingle<Auth>> = <Observable<ResponseSingle<Auth>>>this._authService.login(authenticate);

    authObs.subscribe((res:ResponseSingle<Auth>) => {
      const redirectUrl = this._state ? this._state : '/';
      this._router.navigate([redirectUrl]).then(() => this.loginForm.reset());
    }, (error) => {
      console.log(error);

      // this._toast.warning(error.error.error?.message);
      this._toastService.warn(error.error.error?.message)
    });
  }
}
