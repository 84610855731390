import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor(private _authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const session = this._authService.session.getValue();
    const appSessionHeaderName = `x-session-falck-user`;

    req = req.clone({
      withCredentials: true,
      headers: req.headers.append(appSessionHeaderName, session._id || 'NOSESSION'),
    });

    return next.handle(req).pipe(

        tap((event: HttpEvent<any>) => {
        const isAuth = req.url.search('auth');
          if(isAuth < 0 ) {
              this._authService.setCookie(session);
          }

        }),
        catchError((err: any) => {

            if (err instanceof HttpErrorResponse) {

            if (err.status === 401 || err.status === 403) {

                const isAuth = req.url.search('auth');
                if(isAuth < 0) {

                    this._authService.logout();
                }

            } else {
              this._authService.setCookie(session);
            }
          }
          return throwError(err);
        })
    );
  }
}
