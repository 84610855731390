import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ToastService } from '../../../shared/services/toast.service';
import { LetterVsStats } from '../../../../models/letter-generation-stats.model';

@Component({
  selector: 'app-missing-letters-gauge',
  templateUrl: './missing-letters-gauge.component.html',
  styleUrls: ['./missing-letters-gauge.component.scss']
})
export class MissingLettersGaugeComponent implements OnInit, OnChanges {

  @Input() statsData!:LetterVsStats;

  public chartData!:number;
  public options!: EChartsOption;

  constructor(
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if(!this.chartData) return;
    this._updateChart();
    this._cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.statsData?.data?.missing?.percentage) {
      this.chartData = -1;
      return;
    }

    this.chartData = this.statsData.data.missing.percentage;
    this._updateChart();
    this._cd.detectChanges();
  }


  private _updateChart() {
    const colorWarn = '#d49803';
    const colorError = '#d30001';
    let finalColor = '#0db50d';

    if(this.chartData > 0) {
      finalColor = colorError
    }


    this.options = {
      series: [
        {
          type: 'gauge',
          center: ['50%', '50%'],

          startAngle: -270,
          endAngle: 90,
          min: 0,
          max: 100,
          itemStyle: {
            color: finalColor
          },
          progress: {
            show: true,
            width: 30,
            itemStyle: {

            }
          },

          pointer: {
            show: false
          },
          axisLine: {

            lineStyle: {
              width: 30,
              color: [
                [1, '#666']
              ]
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show:false
          },
          axisLabel: {
            show:false
          },
          anchor: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            width: '50%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, 0],
            fontSize: 50,
            fontWeight: 'bolder',
            formatter: '{value}%',
            color: 'inherit'
          },
          data: [
            {
              value: parseFloat(this.chartData.toFixed(2))
            }

          ]
        },

      ]
    };

  }


}
