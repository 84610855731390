import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestParams } from '../../../models/list';
import { Observable } from 'rxjs';
import { LetterVsByTypeStats, LetterVsStats } from '../../../models/letter-generation-stats.model';
import { ResponseList } from '../../../models/response.model';
import { AriaWfLetterXml } from '../../../models/aria-wf-letter-xml.model';
import { AriaWfLetterItem } from '../../../models/aria-wf-letter-item.model';

@Injectable({
  providedIn: 'root'
})
export class LettersGenerationService {

  private _apiUrl: string = environment.apiUrl;

  constructor(
      private _http:HttpClient
  ) { }

  public getAriaItemVsAriaXmlMissingStats(params:RequestParams):Observable<LetterVsStats> {
    return this._http.get<LetterVsStats>(
        `${this._apiUrl}/letter-generation/stats/aria-item-vs-aria-xml`,
        {params}
    );
  }

  public getAriaItemVsAriaXmlMissingDetails(params:RequestParams):Observable<ResponseList<AriaWfLetterItem>> {
    return this._http.get<ResponseList<AriaWfLetterItem>>(
        `${this._apiUrl}/letter-generation/aria-item-vs-aria-xml`,
        {params}
    );
  }

  public getAriaItemVsAriaXmlMissingDetailsCsv(params:RequestParams):Observable<Blob> {

    return this._http.get(`${this._apiUrl}/letter-generation/aria-item-vs-aria-xml/csv`,
        {
          params: params,
          observe: 'body',
          responseType: 'blob',
          headers: new HttpHeaders({Accept: 'application/csv'})
        });
  }

  public getAriaItemVsAriaXmlMissingByLetterTypeStats(params:RequestParams):Observable<LetterVsByTypeStats> {
    return this._http.get<LetterVsByTypeStats>(
        `${this._apiUrl}/letter-generation/stats/aria-item-vs-aria-xml-by-letter-type`,
        {params}
    );
  }

  public getAriaXmlVsTransformMissingStats(params:RequestParams):Observable<LetterVsStats> {
    return this._http.get<LetterVsStats>(
        `${this._apiUrl}/letter-generation/stats/aria-xml-vs-transform`,
        {params}
    );
  }

  public getAriaXmlVsTransformMissingDetails(params:RequestParams):Observable<ResponseList<AriaWfLetterXml>> {
    return this._http.get<ResponseList<AriaWfLetterXml>>(
        `${this._apiUrl}/letter-generation/aria-xml-vs-transform`,
        {params}
    );
  }

  public getAriaXmlVsTransformMissingDetailsCsv(params:RequestParams):Observable<Blob> {
    return this._http.get(
        `${this._apiUrl}/letter-generation/aria-xml-vs-transform/csv`,
        {
          params: params,
          observe: 'body',
          responseType: 'blob',
          headers: new HttpHeaders({Accept: 'application/csv'})
        }
    );
  }

  public getAriaXmlVsTransformMissingByLetterTypeStats(params:RequestParams):Observable<LetterVsByTypeStats> {
    return this._http.get<LetterVsByTypeStats>(
        `${this._apiUrl}/letter-generation/stats/aria-xml-vs-transform-by-letter-type`,
        {params}
    );
  }
}
