import { TransformLetterType } from './transform-monitor.model';

export interface AriaWfLetterItem {
    _id?:string;
    ariaWfId: string;
    contactId: string | null;
    includeProductTerms:boolean
    status: string;
    destinationType: string;
    letterType: TransformLetterType;
    hasInvoice:boolean;
    invoiceNumber: string;
    xmlStatementNumber:string;
    hasSubscriptionOverview:boolean;
    hasUsageOverview:boolean;
    scenarioCode: string;
    subScenarioCode: string;
    billingGroupId: string;
    usageOverviewStartDate:Date;
    usageOverviewEndDate:Date;
    usageOverviewForInvoiceId: string | null;
    usageOverviewForMPIList: string | null;
    username: string;
    executionTime:Date;
    fallbackExecutionTime:Date;
    errorTime:Date;
    errorMessage: string
    sfCustomerId: number;
    letterCoveringBillingGroupIdList:string;
    is_waiting_for_credit_memo:boolean;
    cancelled_invoice_numbers: string | null;
    createdAt:Date;
    updatedAt:Date;
}

export type AriaWfLetterItemStatus =  'new' | 'processing' | 'processed' | 'failed' | 'RemovedByProcessNETSFile'
export enum AriaWfLetterItemStatuses {
    NEW = 'new',
    PROCESSING = 'processing',
    PROCESSED = 'processed',
    FAILED = 'failed',
    REMOVED_BY_PROCESS_NETS_FILE = 'RemovedByProcessNETSFile'
}
