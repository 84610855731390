import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { SidePanelService } from '../../services/side-panel.service';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit  {

  public sideBarOpen = false;
  @ViewChild('drawer', { static: true }) private drawer!: MatDrawer;

  constructor(public sidePanelService:SidePanelService) { }

  ngOnInit(): void {
    this.sidePanelService.panel = this.drawer;
  }

  onMenuSelected() {
    console.log('MENU SELECTSED ')
  }

}
