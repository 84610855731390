<table
        mat-table
        [dataSource]="dataSource" matSort matSortActive="createDate" matSortDisableClear matSortDirection="desc"
        (matSortChange)="sortData($event)">

    <ng-container matColumnDef="createDate">
        <th mat-header-cell mat-sort-header="createDate" *matHeaderCellDef class="u-th-center">Created Time [UTC]</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.createDate | date: 'dd MMM y, HH:mm:ss':'UTC' }}</td>
    </ng-container>

    <ng-container matColumnDef="ariaWfLetterId">
        <th mat-header-cell mat-sort-header="ariaWfId" *matHeaderCellDef class="u-th-center">Aria WF Id</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.ariaWfId}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header="status" *matHeaderCellDef class="u-th-center">Status</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
            <span class="u-status-box u-status-box--wider-l"
                  [class.u-status-box--ok]="row.status === 'processed'"
                  [class.u-status-box--error]="row.status === 'failed'"
                  [class.u-status-box--orange]="row.status === 'RemovedByProcessNETSFile'"
                  [class.u-status-box--blue]="row.status === 'processing'"
            >{{row.status | araiWfLetterItemStatus}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="letterType">
        <th mat-header-cell mat-sort-header="documentType" *matHeaderCellDef class="u-th-center">Letter Type</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
             <span class="u-status-box u-status-box--wider-l"
                   [class.u-status-box--ok]="row.documentType === 'CardRegistration'"
                   [class.u-status-box--warn]="row.documentType === 'CreditMemo'"
                   [class.u-status-box--error]="row.documentType === 'Dunning'"
                   [class.u-status-box--yellow]="row.documentType === 'Information'"
                   [class.u-status-box--orange]="(row.documentType === 'Invoice' || row.documentType === 'invoice')"
                   [class.u-status-box--blue]="row.documentType === 'PbsRegistration'"
                   [class.u-status-box--purple]="row.documentType === 'ReactivatePlanLetter'"
                   [class.u-status-box--pink]="row.documentType === 'refundInformation'"
                   [class.u-status-box--cyan]="row.documentType === 'Renewal'"
             >{{row.documentType | transformLetterName}}</span>
        </td>


    </ng-container>

    <ng-container matColumnDef="ariaAccountId">
        <th mat-header-cell mat-sort-header="ariaAccountId" *matHeaderCellDef class="u-th-center">Aria Account Id</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.ariaAccountId}}</td>
    </ng-container>

    <ng-container matColumnDef="priority">
        <th mat-header-cell mat-sort-header="priority" *matHeaderCellDef class="u-th-center">Priority</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.priority}}</td>
    </ng-container>

    <ng-container matColumnDef="boomiError">
        <th mat-header-cell mat-sort-header="boomiErrorMessage" *matHeaderCellDef class="u-th-center">Boomi Error</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.boomiErrorMessage}}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="lead-row" *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<ng-container *ngIf="totalCount <= 0">
    <div class="no-data">
        <p>IT LOOKS THERE IS NO DATA ... </p>
    </div>

</ng-container>


<mat-paginator
        *ngIf="totalCount > 0"
        [length]="totalCount"
        color="accent"
        [pageSize]="requestParams.limit"
        [pageIndex]="requestParams.page"
        [showFirstLastButtons]="true"
        (page)="pageChange($event)"
></mat-paginator>
