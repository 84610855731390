import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainContainerComponent } from './modules/core/components/main-container/main-container.component';
import { AuthMainComponent } from './modules/auth/components/auth-main/auth-main.component';
import { SignInComponent } from './modules/auth/components/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './modules/auth/components/forgot-password/forgot-password.component';
import { SetNewPasswordComponent } from './modules/auth/components/set-new-password/set-new-password.component';
import { AuthGuard } from './modules/auth/services/auth.guard';
import { UsersMainComponent } from './modules/users/components/users-main/users-main.component';
import { NetaxeptMainComponent } from './modules/netaxept/components/netaxept-main/netaxept-main.component';
import { HealthMetricsMainComponent } from './modules/health-metrics/health-metrics-main/health-metrics-main.component';
import {
  LettersGenerationMainComponent
} from './modules/letters-generation/components/letters-generation-main/letters-generation-main.component';
import {
  MonitorsNotificationsMainComponent
} from './modules/monitors-notifications/components/monitors-notifications-main/monitors-notifications-main.component';

const routes: Routes = [
  { path: 'auth',
    component: AuthMainComponent,
    title: 'CTS Monitoring',
    children: [
      { path:'sign-in', component: SignInComponent, title: 'CTS Monitoring | Sign in'},
      { path:'forgot-password', component: ForgotPasswordComponent, title: 'CTS Monitoring | Forgot Password' },
      { path:'set-password', component: SetNewPasswordComponent, title: 'CTS Monitoring | Set Password' }
    ]
  },
  {
    path:'',
    canActivate: [AuthGuard],
    component: MainContainerComponent,
    title: 'CTS Monitoring',
    children: [
      { path: 'health-metrics', component: HealthMetricsMainComponent, title: 'CTS Monitoring | Health Metrics'},
      { path: 'netaxept', component: NetaxeptMainComponent, title: 'CTS Monitoring | Netaxept'},
      { path: 'letters-generation', component: LettersGenerationMainComponent, title: 'CTS Monitoring | Letters' +
            ' Generation'},
      { path: 'monitors-notifications', component: MonitorsNotificationsMainComponent, title: 'CTS Monitoring |' +
            ' Monitors Notifications'},
      { path: 'users', component: UsersMainComponent, title: 'CTS Monitoring | Users'},
      { path: '', redirectTo: 'netaxept', pathMatch: 'full'}
    ]
  },
  {
    path: '**',
    redirectTo: 'netaxept',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
