import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersMainComponent } from './components/users-main/users-main.component';
import { SharedModule } from '../shared/shared.module';
import { UserCreateDialogComponent } from './components/user-create-dialog/user-create-dialog.component';
import { UserEditDialogComponent } from './components/user-edit-dialog/user-edit-dialog.component';



@NgModule({
  declarations: [
    UsersMainComponent,
    UserCreateDialogComponent,
    UserEditDialogComponent
  ],
  imports: [
    SharedModule
  ]
})
export class UsersModule { }
