import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestParams } from '../../../models/list';
import { Observable } from 'rxjs';
import { ResponseList, ResponseSingle } from '../../../models/response.model';
import { NetaxeptTransaction } from '../../../models/netaxept-transaction.model';
import { TimeUnit } from '../../../models/units.model';
import { NetaxeptTransactionAggregated } from '../../../models/netaxept-transaction-aggregated.model';
import * as moment from 'moment';
import { NetaxeptErrorCode, NetaxeptErrorCodeByDate } from '../../../models/netaxept-error.model';

@Injectable({
  providedIn: 'root'
})
export class NetaxeptService {

  private _apiUrl: string = environment.apiUrl;
  
  constructor(
      private _http:HttpClient
  ) { }
  
  public getTransactions(params:RequestParams):Observable<ResponseList<NetaxeptTransaction>> {
    return this._http.get<ResponseList<NetaxeptTransaction>>(`${this._apiUrl}/netaxept/transactions`, {params});
  }

  public getAggregatedTransactions(dateFrom:string, dateTo:string, unit: TimeUnit, bin:number):Observable<ResponseList<NetaxeptTransactionAggregated>> {
    const params:HttpParams = new HttpParams({fromObject: {dateFrom, dateTo, unit, bin}});

   return this._http.get<ResponseList<NetaxeptTransactionAggregated>>(`${this._apiUrl}/netaxept/transactions-aggregated`,
       {params});
  }

  public getAccumulatedErrors(params:RequestParams):Observable<ResponseList<NetaxeptErrorCode>> {
    return this._http.get<ResponseList<NetaxeptErrorCode>>(`${this._apiUrl}/netaxept/errors-codes-accumulated`,
        {params});
  }

  public getSlotsCount(params:RequestParams):Observable<ResponseSingle<number>> {
    return this._http.get<ResponseSingle<number>>(`${this._apiUrl}/netaxept/transactions-slots/count`,
        {params});
  }

  public getErrorCodes(params:RequestParams):Observable<ResponseList<NetaxeptErrorCodeByDate>> {
    return this._http.get<ResponseList<NetaxeptErrorCodeByDate>>(`${this._apiUrl}/netaxept/error-codes`,
        {params});
  }
}
