import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastService } from '../../../shared/services/toast.service';
import { PAGE_SIZE_OPTIONS, RequestParams, SortDirections } from '../../../../models/list';
import { AriaWfLetterXml } from '../../../../models/aria-wf-letter-xml.model';
import { TimeTools } from '../../../shared/common/time-tools';
import { ResponseList } from '../../../../models/response.model';
import { TransformMonitor } from '../../../../models/transform-monitor.model';
import { Sort } from '@angular/material/sort';
import { AriaWfService } from '../../services/aria-wf.service';
import { LettersGenerationService } from '../../services/letters-generation.service';

@Component({
  selector: 'app-aria-wf-letter-xmls-table',
  templateUrl: './aria-wf-letter-xmls-table.component.html',
  styleUrls: ['./aria-wf-letter-xmls-table.component.scss']
})
export class AriaWfLetterXmlsTableComponent implements OnInit, OnChanges {
  @Input() statusFilter = '';
  @Input() letterType:string = '';
  @Input() ariaWfId:string = '';
  @Input() dateFrom!:Date | string;
  @Input() dateTo!:Date | string;
  @Input() missing:boolean = false;
  @Input() displayedColumns: string[] = ['createDate', 'ariaWfLetterId', 'letterType', 'status',  'ariaAccountId', 'priority', 'boomiError'];

  dataSource: AriaWfLetterXml[] = [];
  totalCount = 0;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  requestParams: RequestParams = {
    page: 0,
    limit: 25,
    sort: 'createDate',
    order: SortDirections.DESC,
    status: this.statusFilter,
    letterType:this.letterType,
    ariaWfId:this.ariaWfId,
    dateFrom:this.dateFrom,
    dateTo: this.dateTo
  };

  constructor(
      private _ariaWFService:AriaWfService,
      private _letterGenerationService:LettersGenerationService,
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this._getListData();
  }

  ngOnChanges(changes: SimpleChanges) {
    const dates = TimeTools.alignUTCDatesFromTo(this.dateFrom, this.dateTo);

    if(!dates) {
      return;
    }

    this.requestParams['status'] = this.statusFilter;
    this.requestParams['letterType'] = this.letterType;
    this.requestParams['ariaWfId'] = this.ariaWfId;
    this.requestParams['dateFrom'] = dates.from;
    this.requestParams['dateTo'] = dates.to;
    this.requestParams.page = 0;
    this._getListData()
  }

  private _getListData() {
    if(this.missing) {
      this._letterGenerationService.getAriaXmlVsTransformMissingDetails(this.requestParams).subscribe( (response:ResponseList<AriaWfLetterXml>) => {
        this.dataSource = response.data;
        this.totalCount = response.total;
        this._cd.detectChanges();
      });
    } else {
      this._ariaWFService.getLettersXmls(this.requestParams).subscribe( (response:ResponseList<AriaWfLetterXml>) => {
        this.dataSource = response.data;
        this.totalCount = response.total;
        this._cd.detectChanges();
      });
    }


  }

  pageChange(event: any) {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this._getListData();
  }

  sortData(sort:Sort) {
    this.requestParams.sort = sort.active;
    this.requestParams.order = sort.direction;
    this.requestParams.page = 0;
    this._getListData();
  }
}
