import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PAGE_SIZE_OPTIONS, RequestParams, SortDirections } from '../../../../models/list';
import { ToastService } from '../../../shared/services/toast.service';
import { TransformService } from '../../services/transform.service';
import { TimeTools } from '../../../shared/common/time-tools';
import { TransformMonitor } from '../../../../models/transform-monitor.model';
import { Sort } from '@angular/material/sort';
import { ResponseList } from '../../../../models/response.model';

@Component({
  selector: 'app-monitors-table',
  templateUrl: './monitors-table.component.html',
  styleUrls: ['./monitors-table.component.scss']
})
export class MonitorsTableComponent implements OnInit, OnChanges  {
  @Input() statusFilter = '';
  @Input() stateFilter = '';
  @Input() awaitingCreditMemo:boolean | string = '';
  @Input() letterType:string = '';
  @Input() ariaWfId:string = '';
  @Input() dateFrom!:Date | string;
  @Input() dateTo!:Date | string;

  public displayedColumns: string[] = ['timestamp', 'monitoringId', 'state', 'letterType', 'status', 'ariaWfLetterId', 'awaitingCreditMemo', 'tfServer', 'errorMessage',];
  public dataSource: TransformMonitor[] = [];
  public  totalCount = 0;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;
  public requestParams: RequestParams = {
    page: 0,
    limit: 25,
    sort: 'timestamp',
    order: SortDirections.DESC,
    status: this.statusFilter,
    state: this.stateFilter,
    awaitingCreditMemo:this.awaitingCreditMemo,
    letterType:this.letterType,
    ariaWfLetterId:this.ariaWfId,
    dateFrom:this.dateFrom,
    dateTo: this.dateTo
  };

  constructor(
      private _transformService:TransformService,
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this._getListData();
  }

  ngOnChanges(changes: SimpleChanges) {
    const dates = TimeTools.alignUTCDatesFromTo(this.dateFrom, this.dateTo);

    if(!dates) {
      return;
    }

    this.requestParams['state'] = this.stateFilter;
    this.requestParams['status'] = this.statusFilter;
    this.requestParams['awaitingCreditMemo'] = this.awaitingCreditMemo;
    this.requestParams['letterType'] = this.letterType;
    this.requestParams['ariaWfLetterId'] = this.ariaWfId;
    this.requestParams['dateFrom'] = dates.from;
    this.requestParams['dateTo'] = dates.to;
    this.requestParams.page = 0;
    this._getListData()
  }

  private _getListData() {
    this._transformService.getMonitors(this.requestParams).subscribe( (response:ResponseList<TransformMonitor>) => {
      this.dataSource = response.data;
      this.totalCount = response.total;
      this._cd.detectChanges();
    });
  }

  public pageChange(event: any) {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this._getListData();
  }

  public sortData(sort:Sort) {
    this.requestParams.sort = sort.active;
    this.requestParams.order = sort.direction;
    this.requestParams.page = 0;
    this._getListData();
  }


}
