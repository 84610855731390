import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confiramtion-dialog',
  templateUrl: './delete-confiramtion-dialog.component.html',
  styleUrls: ['./delete-confiramtion-dialog.component.scss']
})
export class DeleteConfiramtionDialogComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<DeleteConfiramtionDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public userData: string,
  ) { }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onDeleteClick(): void {
    this.dialogRef.close(true);
  }
}
