import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestParams } from '../../../models/list';
import { Observable } from 'rxjs';
import { ResponseList, ResponseSingle } from '../../../models/response.model';
import { User, UserDto } from '../../../models/user.model';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private _apiUrl: string = environment.apiUrl;

  constructor(
      private _http:HttpClient
  ) { }

  public getList(params:RequestParams):Observable<ResponseList<User>> {
    return this._http.get<ResponseList<User>>(`${this._apiUrl}/users`, {params});
  }

  public getUser(id:string):Observable<ResponseSingle<User>> {
    return this._http.get<ResponseSingle<User>>(`${this._apiUrl}/users/${id}`);
  }


  public edit(id:string, body:UserDto):Observable<ResponseSingle<any>> {
    return this._http.post<ResponseSingle<any>>(`${this._apiUrl}/users/${id}`, body);
  }

  public create(body:UserDto):Observable<ResponseSingle<any>> {
    return this._http.post<ResponseSingle<any>>(`${this._apiUrl}/users`, body);
  }

  public delete(id:string):Observable<ResponseSingle<any>> {
    return this._http.delete<ResponseSingle<any>>(`${this._apiUrl}/users/${id}`);
  }

}
