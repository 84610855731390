import { ChangeDetectorRef, Component, OnInit, ViewContainerRef } from '@angular/core';
import { PAGE_SIZE_OPTIONS, RequestParams } from '../../../../models/list';
import { User, UserRoles } from '../../../../models/user.model';
import { Auth } from '../../../../models/auth.model';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '../../../shared/services/toast.service';
import { AuthService } from '../../../auth/services/auth.service';
import { UsersService } from '../../services/users.service';
import { Sort } from '@angular/material/sort';
import { ResponseList } from '../../../../models/response.model';
import {
  DeleteConfiramtionDialogComponent
} from '../../../shared/components/delete-confiramtion-dialog/delete-confiramtion-dialog.component';
import { UserCreateDialogComponent } from '../user-create-dialog/user-create-dialog.component';
import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';

@Component({
  selector: 'app-users-main',
  templateUrl: './users-main.component.html',
  styleUrls: ['./users-main.component.scss']
})
export class UsersMainComponent implements OnInit{

  public displayedColumns: string[] = ['avatar', 'fullName', 'email', 'username', 'role', 'active', 'lastLogin', 'actions'];
  public dataSource: User[] = [];
  public totalCount = 0;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;
  public requestParams: RequestParams = {
    page: 0,
    limit: 25,
    sort: 'surname',
    order: 'asc'
  };
  public session!:Auth;

  constructor(
      private _usersService: UsersService,
      private _authService: AuthService,
      private _toast:ToastService,
      public _dialog: MatDialog,
      private _cd:ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getListData();
    this.session = this._authService.session.getValue();
  }

  public getListData() {
    this._usersService.getList(this.requestParams).subscribe( (response:ResponseList<User>) => {
      this.dataSource = response.data;
      this.totalCount = response.total;
      this._cd.detectChanges();
    });
  }

  public pageChange(event: any) {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this.getListData();
  }

  public sortData(sort:Sort) {
    this.requestParams.sort = sort.active;
    this.requestParams.order = sort.direction;
    this.getListData();
  }

  public isSuperAdminOrAdmin() {
    return this._authService.isSuperAdmin() || this._authService.isAdmin();
  }

  public isSuperAdmin() {
    return this._authService.isSuperAdmin();
  }

  public getRoleName(role:UserRoles):string {
    let result = '???';
    switch (role) {
      case UserRoles.USER:
        result = 'User';
        break;
      case UserRoles.ADMIN:
        result = 'Admin';
        break;
      case UserRoles.SUPER_ADMIN:
        result = 'Super Admin';
        break;
    }
    return result;
  }

  public createUser() {
    if(!this.isSuperAdminOrAdmin()) {
      return;
    }

    const dialogRef = this._dialog.open(UserCreateDialogComponent, {
      width: '450px', height: '620px', hasBackdrop: true, disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._toast.success(`User created.`)
        this.getListData();
      }

    });
  }


  public editUser(user:User) {
    if(!this.isSuperAdminOrAdmin()) {
      return;
    }

    const dialogRef = this._dialog.open(UserEditDialogComponent, {
      width: '450px', height: '620px', hasBackdrop: true, disableClose: true, data: user._id
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._toast.success(`User updated.`)
        this.getListData();
      }

    });
  }

  public deleteUser(user:User) {
    if(!this.isSuperAdmin()) {
     return;
    }

    const dialogRef = this._dialog.open(DeleteConfiramtionDialogComponent, {
      width: '650px', height: '220px', hasBackdrop: true, disableClose: true, data: `${user.name} ${user.surname}`
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._usersService.delete(user._id).subscribe(() => {
          this._toast.success(`User ${user.name} ${user.surname}  deleted`)
          this.getListData();
        })
      }
    });


  }
}
