import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HealthMetricsMainComponent } from './health-metrics-main/health-metrics-main.component';



@NgModule({
  declarations: [
    HealthMetricsMainComponent
  ],
  imports: [
    SharedModule
  ]
})
export class HealthMetricsModule { }
