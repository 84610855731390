<div class="sidebar">
    <div class="header">
        <div class="header__title">

        </div>
        <div class="user-info">
            <div class="user-info__avatar">
                <p class="user-info__initials">{{ getInitials()}}</p>
            </div>
            <p class="user-info__full-name">{{user?.name}} {{user?.surname}}</p>
            <p class="user-info__username">{{user?.username}}</p>
            <p class="user-info__email">{{user?.email}}</p>
        </div>
    </div>
    <div class="sidebar__content">

        <mat-nav-list class="menu" [disableRipple]="true">
            <mat-divider></mat-divider>
            <a  mat-list-item routerLinkActive="active" routerLink="/health-metrics" class="menu__item" (click)="onMenuClick()">
                <div class="menu__item-link">
                    <mat-icon class="menu__item-icon" fontSet="material-icons-round">bar_chart_4_bars</mat-icon>
                    <span>Health Metrics</span>
                </div>
            </a>

            <mat-divider></mat-divider>
            <a  mat-list-item routerLinkActive="active" routerLink="/netaxept" class="menu__item" (click)="onMenuClick()">
                <div class="menu__item-link">
                    <mat-icon class="menu__item-icon" fontSet="material-icons-outlined">credit_card</mat-icon>
                    <span>Netaxept</span>
                </div>
            </a>

            <mat-divider></mat-divider>
            <a  mat-list-item routerLinkActive="active" routerLink="/letters-generation" class="menu__item" (click)="onMenuClick()">
                <div class="menu__item-link">
                    <mat-icon class="menu__item-icon" fontSet="material-icons-outlined">forward_to_inbox</mat-icon>
                    <span>Letters Generation</span>
                </div>
            </a>

            <mat-divider></mat-divider>
            <a  mat-list-item routerLinkActive="active" routerLink="/monitors-notifications" class="menu__item" (click)="onMenuClick()">
                <div class="menu__item-link">
                    <mat-icon class="menu__item-icon" fontSet="material-icons-outlined">notifications</mat-icon>
                    <span>Monitors Notifications</span>
                </div>
            </a>




            <mat-divider></mat-divider>
            <a mat-list-item routerLinkActive="active" routerLink="/users" class="menu__item" (click)="onMenuClick()">
                <div class="menu__item-link">
                    <mat-icon class="menu__item-icon" fontSet="material-icons-outlined">people</mat-icon>
                    <span>Users</span>
                </div>
            </a>
            <mat-divider></mat-divider>

        </mat-nav-list>

    </div>
    <span class="spacer"></span>


    <div class="footer">
        <span>© 2023 Falck A/S</span>
        <span>powered by <a href="mailto:piotr.skolysz@falck.com" target="_blank" rel="noopener">Piotr Skołysz</a></span>
    </div>


</div>







<!--<nav class="menu">-->
<!--    <a *ngFor="let nav of navigation" routerLinkActive="active" routerLink="{{nav?.link}}" class="menu__item" (click)="onMenuClick()">-->
<!--    <span class="menu__item-content">-->
<!--      <span>{{nav.name}}</span>-->
<!--    </span>-->
<!--    </a>-->
<!--</nav>-->
