import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PAGE_SIZE_OPTIONS, RequestParams } from '../../../../models/list';
import { NetaxeptErrorCode } from '../../../../models/netaxept-error.model';
import { NetaxeptService } from '../../services/netaxept.service';
import { ToastService } from '../../../shared/services/toast.service';
import * as moment from 'moment/moment';
import { ResponseList } from '../../../../models/response.model';
import { Sort } from '@angular/material/sort';
import { TimeTools } from '../../../shared/common/time-tools';
import { NetaxeptErrorCodeInfo } from '../../../../models/netaxept-transaction.model';

@Component({
  selector: 'app-error-types-table',
  templateUrl: './error-types-table.component.html',
  styleUrls: ['./error-types-table.component.scss']
})
export class ErrorTypesTableComponent implements OnInit, OnChanges {
  @Input() dateStartFilter!:Date | string;
  @Input() dateEndFilter!:Date | string;

  public displayedColumns: string[] = ['code', 'message', 'source', 'count'];
  public dataSource: NetaxeptErrorCode[] = [];
  public totalCount = 0;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;
  public requestParams: RequestParams = {
    dateFrom: this.dateStartFilter,
    dateTo: this.dateEndFilter,
    page: 0,
    limit: 14,
  }

  constructor(
      private _netaxeptService:NetaxeptService,
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this._getListData();
  }

  ngOnChanges(changes: SimpleChanges) {
    const dates = TimeTools.alignUTCDatesFromTo(this.dateStartFilter, this.dateEndFilter);
    if(!dates) {
      return;
    }

    this.requestParams['dateFrom'] = dates.from;
    this.requestParams['dateTo'] = dates.to;

    this.requestParams.page = 0;
    this._getListData();
  }

  private _getListData() {
    this._netaxeptService.getAccumulatedErrors(this.requestParams).subscribe( (response:ResponseList<NetaxeptErrorCode>) => {
      this.dataSource = response.data;
      this.totalCount = response.total;
      this._cd.detectChanges();
    });
  }

  public pageChange(event: any) {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this._getListData();
  }

  public sortData(sort:Sort) {
    this.requestParams.sort = sort.active;
    this.requestParams.order = sort.direction;
    this.requestParams.page = 0;
    this._getListData();
  }

  public getErrorAdditionalInfo(errorCode:string) {
    return NetaxeptErrorCodeInfo[`CODE_${errorCode}`];
  }
}
