import { Component } from '@angular/core';

@Component({
  selector: 'app-monitors-notifications-chart',
  templateUrl: './monitors-notifications-chart.component.html',
  styleUrls: ['./monitors-notifications-chart.component.scss']
})
export class MonitorsNotificationsChartComponent {

}
