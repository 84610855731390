<table
        mat-table
        [dataSource]="dataSource" matSort matSortActive="timestamp" matSortDisableClear matSortDirection="desc"
        (matSortChange)="sortData($event)">

    <ng-container matColumnDef="timestamp">
        <th mat-header-cell mat-sort-header="timestamp" *matHeaderCellDef class="u-th-center">Timestamp</th>
        <td mat-cell *matCellDef="let row">{{row.timestamp | date: 'dd MMM y, HH:mm:ss':'UTC' }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header="status" *matHeaderCellDef class="u-th-center">Status</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
            <span class="u-status-box"
            [class.u-status-box--ok]="row.status === 'OK'"
            [class.u-status-box--warn]="row.status === 'WARNING'"
            [class.u-status-box--error]="row.status === 'ERROR'"
            >
                {{row.status}}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell mat-sort-header="amount" *matHeaderCellDef class="u-th-center">Amount</th>
        <td mat-cell *matCellDef="let row">
            <span class="u-cursor-default"
                    [class.u-color-warn--bold]="row.amount > row.capturedAmount && row.amount > 1"
                    [class.u-color-error--bold]="row.capturedAmount <= 0 && row.amount > 1"
                    [matTooltip]="'amount: ' + (row.amount | currency: row.currency:row.currency+ ' ') + ',\ncaptured: '+(row.capturedAmount | currency: row.currency:row.currency+ ' ')"
                  matTooltipClass="u-multiline-tooltip"

            >{{row.amount | currency: row.currency:row.currency+ ' '}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="capturedAmount">
        <th mat-header-cell mat-sort-header="capturedAmount" *matHeaderCellDef class="u-th-center">Captured Amount</th>
        <td mat-cell *matCellDef="let row">{{row.capturedAmount}}</td>
    </ng-container>

    <ng-container matColumnDef="issuer">
        <th mat-header-cell mat-sort-header="issuer" *matHeaderCellDef class="u-th-center">Issuer</th>
        <td mat-cell *matCellDef="let row" class="u-td-center" [matTooltip]="row.issuer">
            <img *ngIf="row.issuer === 'Dankort'" src="assets/dankort.png" style="height: 16px;">
            <img src="assets/visa_white.png" *ngIf="row.issuer === 'Visa'" style="height: 12px;">
            <img src="assets/mastercard.png" *ngIf="row.issuer === 'MasterCard'" style="height: 18px;">
            <mat-icon *ngIf="row.issuer === 'Easypayment'"  fontSet="material-icons-outlined" >credit_card</mat-icon>
        </td>

    </ng-container>

    <ng-container matColumnDef="transactionId">
        <th mat-header-cell mat-sort-header="transactionId" *matHeaderCellDef class="u-th-center">Transaction Id</th>
        <td mat-cell *matCellDef="let row">{{row.transactionId}}</td>
    </ng-container>

    <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="" class="u-th-center">Error</th>
        <td mat-cell *matCellDef="let row">
            {{row?.errorDetails.length > 0 ? '['+ row.errorDetails[0].responseSource +'] '+ row.errorDetails[0].responseCode +' - ' + row.errorDetails[0].responseMessage  : '-'}}

            <span *ngIf="row?.errorDetails.length > 1" class="u-color-warn--bold u-cursor-default" matTooltipClass="u-multiline-tooltip"
                  [matTooltip]="'['+ row.errorDetails[1].responseSource +'] '+ row.errorDetails[1].responseCode +' - ' + row.errorDetails[1].responseMessage"
            >+{{row?.errorDetails.length-1}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="orderNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="orderNumber" class="u-th-center">Order Number</th>
        <td mat-cell *matCellDef="let row">{{row.orderNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="errorDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="errorDescription" class="u-th-center">Error Description</th>
        <td mat-cell *matCellDef="let row">{{row?.errorDetails.length > 0 ? row?.errorDetails[0].description : '-'}}</td>
    </ng-container>


    <ng-container matColumnDef="maskedPan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="maskedPan" class="u-th-center">Masked Pan</th>
        <td mat-cell *matCellDef="let row">{{row?.errorDetails.length > 0 ? row?.errorDetails[0].maskedPan : '-'}}</td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="lead-row" *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<ng-container *ngIf="totalCount <= 0">
    <div class="no-data">
        <p>IT LOOKS THERE IS NO DATA ... </p>
    </div>

</ng-container>


<mat-paginator
        *ngIf="totalCount > 0"
        [length]="totalCount"
        color="accent"
        [pageSize]="requestParams.limit"
        [pageIndex]="requestParams.page"
        [showFirstLastButtons]="true"
        (page)="pageChange($event)"
></mat-paginator>
