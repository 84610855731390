<div class="container">

    <div class="container__content card">
        <div>
            <div class="logo">
                <img src="assets/flack-logo.png" alt="Flack A/S logo"/>
                <span>CTS Monitoring</span>
            </div>
            <div>
                <h3>If you forget your password, provide your email address and click below.</h3>
                <form (ngSubmit)="onSubmit()" [formGroup]="forgotPasswordForm" autocomplete="off" class="forgot-password-form"
                      name="forgot-password-from" novalidate>
                    <mat-form-field color="primary">
                        <mat-label>Email</mat-label>
                        <input
                                matInput
                                autocomplete="off"
                                id="email"
                                name="email"
                                placeholder="Email"
                                formControlName="email"
                                required
                        />
                    </mat-form-field>

                    <div class="return-to-login">
                        <a class="return-to-login__link" routerLink="/auth/sign-in">
                            * Sign in
                        </a>
                    </div>

                    <button
                            mat-flat-button
                            color="primary"
                            class="submit-button"
                            type="submit"
                            [disabled]="forgotPasswordForm.invalid"
                    >REMEMBER PASSWORD
                    </button>

                    <div class="footer">
                        <span><strong>v{{version}}</strong></span>
                        <span>powered by <a href="mailto:piotr.skolysz@falck.com" target="_blank" rel="noopener nofollow noreferrer">Piotr Skołysz</a></span>
                    </div>

                </form>
            </div>
        </div>

    </div>

</div>
