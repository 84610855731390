import { NgModule } from '@angular/core';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    MainContainerComponent,
    TopBarComponent,
    NavigationComponent
  ],
  imports: [
    SharedModule
  ]
})
export class CoreModule { }
