import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NetaxeptErrorCode } from '../../../../models/netaxept-error.model';
import { EChartsOption } from 'echarts';
import { LetterVsByTypeStats, MissingLetterType } from '../../../../models/letter-generation-stats.model';
import { TransformLetterTypesPipe } from '../../../shared/pipes/transform-letter-types.pipe';

@Component({
  selector: 'app-missing-letters-types-chart',
  templateUrl: './missing-letters-types-chart.component.html',
  styleUrls: ['./missing-letters-types-chart.component.scss']
})
export class MissingLettersTypesChartComponent implements OnInit, OnChanges {
  @Input() statsData!:LetterVsByTypeStats;

  public chartData!:MissingLetterType[];
  public options!: EChartsOption;

  constructor(
      private _letterTypePipe: TransformLetterTypesPipe,
      private _cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    // this._updateChart();
    // this._cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.statsData?.data.missing) {
      return;
    }
    this.chartData = this.statsData.data.missing;
    this._updateChart();
    this._cd.detectChanges();
  }

  private _updateChart() {

    const data:{value:number, name:string}[] = []

    for(let item of this.chartData) {
      data.push({
        name: this._letterTypePipe.transform(item.letterType) ,
        value: item.count
      });
    }

    this.options = {
      darkMode: true,
      color: [
        '#5470c6', '#91cc75', '#fac858', '#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc'
      ],
      colorBy: 'series',
      gradientColor: ['#f6efa6','#d88273','#bf444c'],
      series: {
        data: data,
        type: 'pie',
        radius: [50, 150],
        center: ['50%', '50%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 8
        },
      },
      aria:{
        decal: {
          show: true,
          decals:[
            {
              color: "rgba(0, 0, 0, 0.2)",
              dashArrayX: [1,0],
              dashArrayY: [2,5],
              symbolSize:1,
              rotation: 0.5235987755982988,
              symbol: 'rect',
              symbolKeepAspect: true,
              maxTileHeight: 512,
              maxTileWidth: 512
            },
            {
              color: "rgba(0, 0, 0, 0.2)",
              dashArrayX: [[8,8],[0,8,8,0]],
              dashArrayY: [6,0],
              symbolSize:0.8,
              rotation: 0,
              symbol: 'circle',
              symbolKeepAspect: true,
              maxTileHeight: 512,
              maxTileWidth: 512
            },
            {
              color: "rgba(0, 0, 0, 0.2)",
              dashArrayX: [[6,6],[0,6,6,0]],
              dashArrayY: [6,0],
              symbolSize:1,
              rotation: 0,
              symbol: 'rect',
              symbolKeepAspect: true,
              maxTileHeight: 512,
              maxTileWidth: 512
            },
            {
              color: "rgba(0, 0, 0, 0.2)",
              dashArrayX: [[1,0],[1,6]],
              dashArrayY: [1,0,6,0],
              symbolSize:1,
              rotation: 0.7853981633974483,
              symbol: 'rect',
              symbolKeepAspect: true,
              maxTileHeight: 512,
              maxTileWidth: 512
            },

          ]
        }
      },
      tooltip: {
        show: true
      },
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      }
    };
  }
}
