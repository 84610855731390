<table
        mat-table
        [dataSource]="dataSource" matSort matSortActive="timestamp" matSortDisableClear matSortDirection="desc"
        (matSortChange)="sortData($event)">

    <ng-container matColumnDef="timestamp">
        <th mat-header-cell mat-sort-header="timestamp" *matHeaderCellDef class="u-th-center">Timestamp [UTC]</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.timestamp | date: 'dd MMM y, HH:mm:ss':'UTC' }}</td>
    </ng-container>

    <ng-container matColumnDef="monitoringId">
        <th mat-header-cell mat-sort-header="monitoringId" *matHeaderCellDef class="u-th-center">Monitor Id</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.monitoringId}}</td>
    </ng-container>

    <ng-container matColumnDef="state">
        <th mat-header-cell mat-sort-header="state" *matHeaderCellDef class="u-th-center">State</th>
        <td mat-cell *matCellDef="let row" class="u-td-center"><span class="u-status-box">{{row.state}}</span></td>
    </ng-container>

    <ng-container matColumnDef="letterType">
        <th mat-header-cell mat-sort-header="letterType" *matHeaderCellDef class="u-th-center">Letter Type</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
            <span class="u-status-box u-status-box--wider-l"
                  [class.u-status-box--ok]="row.letterType === 'CardRegistration'"
                  [class.u-status-box--warn]="row.letterType === 'CreditMemo'"
                  [class.u-status-box--error]="row.letterType === 'Dunning'"
                  [class.u-status-box--yellow]="row.letterType === 'Information'"
                  [class.u-status-box--orange]="row.letterType === 'Invoice'"
                  [class.u-status-box--blue]="row.letterType === 'PbsRegistration'"
                  [class.u-status-box--purple]="row.letterType === 'ReactivatePlanLetter'"
                  [class.u-status-box--pink]="row.letterType === 'refundInformation'"
            >{{row.letterType | transformLetterName}}</span>


            </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header="status" *matHeaderCellDef class="u-th-center">Status</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
            <span class="u-status-box"
                  [class.u-status-box--ok]="row.status === 'Success'"
                  [class.u-status-box--error]="row.status === 'Error'"
            >
                {{row.status}}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="awaitingCreditMemo">
        <th mat-header-cell mat-sort-header="awaitingCreditMemo" *matHeaderCellDef class="u-th-center">Awaiting Credit Memo</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">
            <mat-icon color="warn" *ngIf="row.awaitingCreditMemo">done</mat-icon>
            <mat-icon class="u-color-green"  *ngIf="!row.awaitingCreditMemo">close</mat-icon>
        </td>
    </ng-container>


    <ng-container matColumnDef="tfServer">
        <th mat-header-cell mat-sort-header="tfServer" *matHeaderCellDef class="u-th-center">Server</th>
        <td mat-cell *matCellDef="let row">{{row.tfServer}}</td>
    </ng-container>


    <ng-container matColumnDef="errorMessage">
        <th mat-header-cell mat-sort-header="errorMessage" *matHeaderCellDef class="u-th-center">Error</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.errorMessage?row.errorMessage:'-'}}</td>
    </ng-container>


    <ng-container matColumnDef="ariaWfLetterId">
        <th mat-header-cell mat-sort-header="ariaWfLetterId" *matHeaderCellDef class="u-th-center">Aria WF Id</th>
        <td mat-cell *matCellDef="let row" class="u-td-center">{{row.ariaWfLetterId}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="lead-row" *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<ng-container *ngIf="totalCount <= 0">
    <div class="no-data">
        <p>IT LOOKS THERE IS NO DATA ... </p>
    </div>

</ng-container>


<mat-paginator
        *ngIf="totalCount > 0"
        [length]="totalCount"
        color="accent"
        [pageSize]="requestParams.limit"
        [pageIndex]="requestParams.page"
        [showFirstLastButtons]="true"
        (page)="pageChange($event)"
></mat-paginator>
