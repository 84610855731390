<div *ngIf="chartData && chartData >=0 ">
    <div echarts [options]="options"></div>
    <div class="chart-footer">
        <span><strong>Total letters - </strong> {{(statsData)?statsData.data.total:0 }}</span>
        <span><strong>Missing letters - </strong> {{(statsData)? statsData.data.missing.amount : 0}}</span>
    </div>
</div>
<div *ngIf="!chartData || chartData < 0" class="no-data">
    <p>IT LOOKS THERE IS NO DATA ... </p>
</div>



