import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NetaxeptErrorCode } from '../../../../models/netaxept-error.model';
import { PAGE_SIZE_OPTIONS, RequestParams, SortDirections } from '../../../../models/list';
import { ToastService } from '../../../shared/services/toast.service';
import { MonitorsNotificationsService } from '../../services/monitors-notifications.service';
import { TimeTools } from '../../../shared/common/time-tools';
import { ResponseList } from '../../../../models/response.model';
import { Sort } from '@angular/material/sort';
import { MonitorNotification, MonitorNotificationFact } from '../../../../models/monitor-notification.model';

@Component({
  selector: 'app-monitors-notifications-table',
  templateUrl: './monitors-notifications-table.component.html',
  styleUrls: ['./monitors-notifications-table.component.scss']
})
export class MonitorsNotificationsTableComponent implements OnInit, OnChanges {

  @Input() dateFrom!:Date | string;
  @Input() dateTo!:Date | string;
  @Input() status!:string;
  @Input() type!:string;
  @Input() info!:string;

  public displayedColumns: string[] = ['updatedAt', 'type', 'job', 'title', 'facts', 'status'];
  public dataSource: MonitorNotification[] = [];
  public totalCount = 0;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;
  public requestParams: RequestParams = {
    dateFrom: this.dateFrom,
    dateTo: this.dateTo,
    status: this.status,
    type: this.type,
    subtitle: this.info,
    page: 0,
    limit: 25,
    sort: 'updatedAt',
    order: SortDirections.DESC
  }


  constructor(
      private _monitorsNotificationsService:MonitorsNotificationsService,
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this._getListData();
  }

  ngOnChanges(changes: SimpleChanges) {
    const dates = TimeTools.alignUTCDatesFromTo(this.dateFrom, this.dateTo);
    if(!dates) {
      return;
    }

    this.requestParams['dateFrom'] = dates.from;
    this.requestParams['dateTo'] = dates.to;
    this.requestParams['status'] = this.status
    this.requestParams['type'] = this.type;
    this.requestParams['subtitle'] = this.info;

    this.requestParams.page = 0;
    this._getListData();
  }

  private _getListData() {
    this._monitorsNotificationsService.getNotifications(this.requestParams).subscribe( (response:ResponseList<MonitorNotification>) => {
      this.dataSource = response.data;
      this.totalCount = response.total;
      this._cd.detectChanges();
    });
  }

  public pageChange(event: any) {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this._getListData();
  }

  public sortData(sort:Sort) {
    this.requestParams.sort = sort.active;
    this.requestParams.order = sort.direction;
    this.requestParams.page = 0;
    this._getListData();
  }

  public formatJobName(value:string):string {
    return value.split('-').join(' ');
  }

  // public formatFacts(facts:MonitorNotificationFact[]): string {
  //   facts.shift();
  //   let result:string ='';
  //
  //   for (let fact of facts) {
  //     result += fact.name +': '+ fact.value+', ';
  //   }
  //
  //   return result;
  //
  // }


}
