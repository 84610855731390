import { NgModule } from '@angular/core';
import { LettersGenerationMainComponent } from './components/letters-generation-main/letters-generation-main.component';
import { SharedModule } from '../shared/shared.module';
import { MonitorsTableComponent } from './components/monitors-table/monitors-table.component';
import { AriaWfLetterItemsTableComponent } from './components/aria-wf-letter-items-table/aria-wf-letter-items-table.component';
import { AriaWfLetterXmlsTableComponent } from './components/aria-wf-letter-xmls-table/aria-wf-letter-xmls-table.component';
import { MissingLettersGaugeComponent } from './components/missing-letters-gauge/missing-letters-gauge.component';
import { MissingLettersTypesChartComponent } from './components/missing-letters-types-chart/missing-letters-types-chart.component';



@NgModule({
  declarations: [
    LettersGenerationMainComponent,
    MonitorsTableComponent,
    AriaWfLetterItemsTableComponent,
    AriaWfLetterXmlsTableComponent,
    MissingLettersGaugeComponent,
    MissingLettersTypesChartComponent,
  ],
  imports: [
    SharedModule
  ]
})
export class LettersGenerationModule { }
