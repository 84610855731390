import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DateTime } from 'luxon';
import { Auth } from '../../../models/auth.model';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { Authenticate } from '../../../models/authenticate.model';
import { ResponseError, ResponseSingle } from '../../../models/response.model';
import { UserRoles } from '../../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public session = new BehaviorSubject<Auth>({});

  private _apiUrl: string = environment.apiUrl;
  private _cookieName: string = 'FalckCTSMonitoringAuth';
  private _authCookieExpirationTimeInMinutes = 60;

  constructor(
      private _http:HttpClient,
      private _cookieService:CookieService,
      private _router: Router,
      private _toastService: ToastService
  ) {
    if (this._cookieService.check(this._cookieName)) {
      this.session.next(JSON.parse(this._cookieService.get(this._cookieName)));
    }
  }

  public login(auth:Authenticate): Observable<ResponseSingle<Auth> | ResponseError | ErrorEvent> {
    return this._http
        .post<ResponseSingle<Auth>>(`${this._apiUrl}/users/auth`, auth)
        .pipe(
            tap((response:ResponseSingle<Auth>) => {
              this._handleAuthentication(response.data);
            }),
            tap( (response:ResponseSingle<Auth>) => {
              this._toastService.success('You are signed in!')
            })
        )
  }

  public forgetPassword(email:string):Observable<ResponseSingle<any> | ResponseError | ErrorEvent> {
    return this._http
        .post<ResponseSingle<any>>(`${this._apiUrl}/users/forgot-password`, {email});
  }

  public setNewPassword(token:string, password:string):Observable<ResponseSingle<any> | ResponseError | ErrorEvent> {
    return this._http
        .post<ResponseSingle<any>>(`${this._apiUrl}/users/set-new-password/${token}`, {password});
  }

  public logout(state?: string) {
    this._http.delete(`${this._apiUrl}/users/auth`).subscribe()
    this.session.next({});
    this._router.navigate(['/auth/sign-in']);
    this._cookieService.delete(this._cookieName);
    this._toastService.warn('You have been logged out.');
  }

  public redirectToLogin(state?: string) {
    if (state) {
      return this._router.navigate(['/auth/sign-in'], { queryParams: { state } });
    } else {
      return this._router.navigate(['/auth/sign-in']);
    }
  }

  public setCookie(session: Auth) {
    const now = DateTime.now();
    const expirationDate = now.plus({ minutes: this._authCookieExpirationTimeInMinutes}).toJSDate();

    this._cookieService.set(this._cookieName, JSON.stringify(session), {
      expires: expirationDate,
      secure: true,
      sameSite: 'None',
      path:'/'
    } );
  }

  public checkCookieToken():boolean {
    if(this._cookieService.check(this._cookieName)) {
      const cookie:Auth = JSON.parse(this._cookieService.get(this._cookieName))
      return (!!cookie?._id && !!cookie?.user);
    }
    return false;
  }

  public isSuperAdmin():boolean {
    const session = this.session.getValue()
    return session?.user?.role === UserRoles.SUPER_ADMIN;
  }

  public isAdmin():boolean {
    const session = this.session.getValue()
    return session?.user?.role === UserRoles.ADMIN;
  }

  private _handleAuthentication(session: Auth): void {
    this.session.next(session);
    this.setCookie(session);
  }

}
