<table
        mat-table
        [dataSource]="dataSource" matSort matSortActive="firstTimeShowUp" matSortDisableClear matSortDirection="desc"
        (matSortChange)="sortData($event)">

    <ng-container matColumnDef="code">
        <th mat-header-cell mat-sort-header="responseCode" *matHeaderCellDef class="u-th-center">Response Code</th>
        <td mat-cell *matCellDef="let row">{{row.responseCode}}</td>
    </ng-container>

    <ng-container matColumnDef="message">
        <th mat-header-cell mat-sort-header="responseMessage" *matHeaderCellDef class="u-th-center">Response Message</th>
        <td mat-cell *matCellDef="let row"><strong>{{row.responseMessage}}</strong> <span *ngIf="getErrorAdditionalInfo(row.responseCode)" class="additional-info"> - {{getErrorAdditionalInfo(row.responseCode)}}</span></td>
    </ng-container>

    <ng-container matColumnDef="source">
        <th mat-header-cell mat-sort-header="responseSource" *matHeaderCellDef class="u-th-center">Response Source</th>
        <td mat-cell *matCellDef="let row">{{row.responseSource}}</td>
    </ng-container>

    <ng-container matColumnDef="first">
        <th mat-header-cell mat-sort-header="firstTimeShowUp" *matHeaderCellDef class="u-th-center">First Time</th>
        <td mat-cell *matCellDef="let row">{{row.firstTimeShowUp | date: 'dd MMM y'}}</td>
    </ng-container>

    <ng-container matColumnDef="last">
        <th mat-header-cell mat-sort-header="lastTimeShowUp" *matHeaderCellDef class="u-th-center">Last Time</th>
        <td mat-cell *matCellDef="let row">{{row.lastTimeShowUp | date: 'dd MMM y'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="lead-row" *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<ng-container *ngIf="totalCount <= 0">
    <div class="no-data">
        <p>IT LOOKS THERE IS NO DATA ... </p>
    </div>

</ng-container>


<mat-paginator
        *ngIf="totalCount > 0"
        [length]="totalCount"
        color="accent"
        [pageSize]="requestParams.limit"
        [pageIndex]="requestParams.page"
        [showFirstLastButtons]="true"
        (page)="pageChange($event)"
></mat-paginator>
