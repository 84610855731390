import { Pipe, PipeTransform } from '@angular/core';
import { AriaWfLetterItemStatus, AriaWfLetterItemStatuses } from '../../../models/aria-wf-letter-item.model';

@Pipe({name: 'araiWfLetterItemStatus'})
export class AriaWfLetterItemsStatusesPipe implements  PipeTransform{
    transform(letterType: AriaWfLetterItemStatus): string {


          let result:string ='unknown';

          switch(letterType) {
              case AriaWfLetterItemStatuses.NEW:
                  result = 'New';
                  break;
              case AriaWfLetterItemStatuses.PROCESSING:
                  result = 'Processing';
                  break;
              case AriaWfLetterItemStatuses.PROCESSED:
                  result = 'Processed';
                  break;
              case AriaWfLetterItemStatuses.FAILED:
                  result = 'Failed';
                  break;
              case AriaWfLetterItemStatuses.REMOVED_BY_PROCESS_NETS_FILE:
                  result = 'Removed by NETS file';
                  break;

          }

          return result;
    }
}
