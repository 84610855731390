import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TimeTools } from '../../../shared/common/time-tools';
import { NetaxeptService } from '../../services/netaxept.service';
import { ToastService } from '../../../shared/services/toast.service';

import { RequestParams } from '../../../../models/list';
import { EChartsOption } from 'echarts';
import { zip } from 'rxjs';

@Component({
  selector: 'app-transaction-slots-gauge',
  templateUrl: './transaction-slots-gauge.component.html',
  styleUrls: ['./transaction-slots-gauge.component.scss']
})
export class TransactionSlotsGaugeComponent implements OnChanges{
  @Input() dateFrom!:Date | string;
  @Input() dateTo!:Date | string;
  @Input() isWarn:boolean = false;
  @Input() errorsError:boolean | null= false;
  @Input() errorsWarn:boolean | null = false;
  @Input() warningsError:boolean | null= false;
  @Input() warningsWarn:boolean | null= false;

  public requestParams: RequestParams = {
    dateFrom: this.dateFrom,
    dateTo: this.dateTo,
  }

  public chartData:number = 0;
  public options!: EChartsOption;
  public slotsInfo = {total: 0, value:0};



  constructor(
      private _netaxeptService:NetaxeptService,
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  private _getTotal() {
    return this._netaxeptService.getSlotsCount(this.requestParams);
  }

  private _getDedicated() {
    const tmpRP = {...this.requestParams};
    if(this.errorsError != null) tmpRP['errorsError'] = this.errorsError;
    if(this.errorsWarn != null) tmpRP['errorsWarn'] = this.errorsWarn;
    if(this.warningsError != null)tmpRP['warningsError'] = this.warningsError;
    if(this.warningsWarn != null) tmpRP['warningsWarn'] = this.warningsWarn;
    return this._netaxeptService.getSlotsCount(tmpRP);
  }


  ngOnChanges(changes: SimpleChanges) {
    const dates = TimeTools.alignUTCDatesFromTo(this.dateFrom, this.dateTo);
    if (!dates) {
      return;
    }

    this.requestParams['dateFrom'] = dates.from;
    this.requestParams['dateTo'] = dates.to;
    this._getListData();
  }

  private _getListData() {
    zip(this._getTotal(), this._getDedicated())
        .subscribe(data => {
          this.chartData = this._calculatePercentage(data[0].data, data[1].data);

          this.slotsInfo.value = data[1].data;
          this.slotsInfo.total = data[0].data;
          this._updateChart();
          this._cd.detectChanges();
        });
  }

  private _updateChart() {
    const colorWarn = '#d49803';
    const colorError = '#d30001';
    let finalColor = '#0db50d';

    if(this.chartData > 0) {
      finalColor = colorError
      if(this.isWarn) {
        finalColor = colorWarn;
      }
    }


    this.options = {
      series: [
        {
          type: 'gauge',
          center: ['50%', '50%'],

          startAngle: -270,
          endAngle: 90,
          // startAngle: 200,
          // endAngle: -20,
          min: 0,
          max: 100,
          itemStyle: {
            color: finalColor
          },
          progress: {
            show: true,
            width: 30,
            itemStyle: {

            }
          },

          pointer: {
            show: false
          },
          axisLine: {

            lineStyle: {
              width: 30,
              color: [
                [1, '#666']
              ]
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
           show:false
          },
          axisLabel: {
            show:false
          },
          anchor: {
            show: false
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            width: '50%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, 0],
            fontSize: 50,
            fontWeight: 'bolder',
            formatter: '{value}%',
            color: 'inherit'
          },
          data: [
            {
              value: parseFloat(this.chartData.toFixed(2))
            }

          ]
        },

      ]
    };

  }

  private _calculatePercentage(total:number,  value:number):number {
    let result  = value/total*100;

    return isNaN(result)?0:result
  }
}
