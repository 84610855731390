import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetaxeptMainComponent } from './components/netaxept-main/netaxept-main.component';
import { SharedModule } from '../shared/shared.module';
import { TransactionsTableComponent } from './components/transactions-table/transactions-table.component';
import { TransactionsChartComponent } from './components/transactions-chart/transactions-chart.component';
import { ErrorTypesChartComponent } from './components/error-types-chart/error-types-chart.component';
import { ErrorTypesTableComponent } from './components/error-types-table/error-types-table.component';
import { TransactionSlotsGaugeComponent } from './components/transaction-slots-gauge/transaction-slots-gauge.component';
import { ErrorCodesTableComponent } from './components/error-codes-table/error-codes-table.component';



@NgModule({
  declarations: [
    NetaxeptMainComponent,
    TransactionsTableComponent,
    TransactionsChartComponent,
    ErrorTypesChartComponent,
    ErrorTypesTableComponent,
    TransactionSlotsGaugeComponent,
    ErrorCodesTableComponent
  ],
  imports: [
      SharedModule


  ]
})
export class NetaxeptModule { }
