export interface User {
  _id: string;
  name: string;
  surname: string;
  username: string
  email: string;
  role: UserRole;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  lastLoginAt: Date | null;
}

export interface UserDto {
  name: string;
  surname: string;
  username: string
  email: string;
  role: UserRole;
  active: boolean;
}

export type UserRole = 'S' | 'A' | 'U';
export enum UserRoles {
  SUPER_ADMIN = 'S',
  ADMIN = 'A',
  USER = 'U',
}
