import * as moment from 'moment';

export class TimeTools {

    public static alignUTCDatesFromTo(dateFrom:Date | string, dateTo: Date | string) {

        let from:string;
        let to:string;

        if(!dateFrom || !dateTo) {
            return;
        }

        if(dateFrom instanceof moment) {
            from = moment(dateFrom).toISOString()
        } else  {
            from = dateFrom.toString();
        }

        if(dateTo instanceof moment) {
            to = moment(dateTo).utc().set({hour: 23, minute: 59, second: 59, millisecond: 999}).toISOString()
        } else  {
            to = dateTo.toString();
        }

        return {from, to};



    }
}
