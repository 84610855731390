import { Component } from '@angular/core';

@Component({
  selector: 'app-health-metrics-main',
  templateUrl: './health-metrics-main.component.html',
  styleUrls: ['./health-metrics-main.component.scss']
})
export class HealthMetricsMainComponent {

}
