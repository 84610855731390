export interface TransformMonitor {
    _id:string;
    monitoringId:number;
    state:TransformState;
    timestamp:Date;
    letterType:TransformLetterType;
    ariaWfLetterId:string;
    awaitingCreditMemo:boolean;
    tfServer:string;
    status:TransformStatus;
    errorMessage:string | null;
    createdAt:Date;
    updatedAt:Date;
}

export type TransformState = 'Ingested';
export enum TransformStates {
    INGESTED = 'Ingested'
}

export type TransformStatus = 'Error' | 'Success';

export enum TransformStatuses {
    ERROR = 'Error',
    SUCCESS = 'Success'
}

export type TransformLetterType =  'CardRegistration' | 'CreditMemo' | 'Dunning' | 'Information' | 'Invoice' | 'invoice' | 'PbsRegistration' | 'ReactivatePlanLetter' | 'refundInformation' |
 'Renewal'
export enum TransformLetterTypes {
    CARD_REGISTRATION = 'CardRegistration',
    CREDIT_MEMO = 'CreditMemo',
    DUNNING = 'Dunning',
    INFORMATION = 'Information',
    INVOICE = 'Invoice',
    INVOICE_S = 'invoice',
    PBS_REGISTRATION = 'PbsRegistration',
    REACTIVATE_PLAN_LETTER = 'ReactivatePlanLetter',
    REFUND_INFORMATION = 'refundInformation',
    RENEWAL = 'Renewal',
}
