import { Pipe, PipeTransform } from '@angular/core';
import { MonitorNotificationFact } from '../../../models/monitor-notification.model';

@Pipe({name: 'notificationsFacts'})
export class MoitorNotificationFactsPipe implements  PipeTransform{
    transform(facts: MonitorNotificationFact[]): string {


          let result:string ='<p>';
          let i = 0;

          for (let fact of facts) {
            if(i === 0) {
                i++;
                continue;
            }
            result += '<strong>'+fact.name +'</strong>: '+ fact.value;

            if(facts.length-1 > i) {
                result += '<br>';
            }
            if(facts.length-1 === i){
             result +="</p>";
            }

            i++;

          }

          return result;
    }
}
