import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitorsNotificationsMainComponent } from './components/monitors-notifications-main/monitors-notifications-main.component';
import { SharedModule } from '../shared/shared.module';
import { MonitorsNotificationsTableComponent } from './components/monitors-notifications-table/monitors-notifications-table.component';
import { MonitorsNotificationsChartComponent } from './components/monitors-notifications-chart/monitors-notifications-chart.component';



@NgModule({
  declarations: [
    MonitorsNotificationsMainComponent,
    MonitorsNotificationsTableComponent,
    MonitorsNotificationsChartComponent
  ],
  imports: [
    SharedModule
  ]
})
export class MonitorsNotificationsModule { }
