import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{

  constructor(
    private _authService: AuthService,
    private router: Router
  ) {}

  canActivate(): boolean | Promise<boolean> | Observable<boolean> {
    const isLoggedIn = this._authService.checkCookieToken()
    if (!isLoggedIn) {
      return this._authService.redirectToLogin(window.location.pathname);
    } else {
      return true;
    }
  }

}
