import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../services/users.service';
import { UserDto } from '../../../../models/user.model';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-user-create-dialog',
  templateUrl: './user-create-dialog.component.html',
  styleUrls: ['./user-create-dialog.component.scss']
})
export class UserCreateDialogComponent implements OnInit{

  public userForm!:FormGroup;

  constructor(
      private _formBuilder:FormBuilder,
      private _usersService:UsersService,
      private _toast:ToastService,
      public dialogRef: MatDialogRef<UserCreateDialogComponent>
  ) { }

  ngOnInit() {
    this._creatForm();
  }

  private _creatForm() {
    this.userForm = this._formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      surname: new FormControl('', [Validators.required, Validators.minLength(3)]),
      username: new FormControl('', [Validators.required, Validators.minLength(3)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('U', [Validators.required]),
      active: new FormControl(false, [Validators.required]),
    });
  }


  public onCancel() {
    this.dialogRef.close(false);
  }

  public onSubmit() {
    const user:UserDto = this.userForm.getRawValue();
    this._usersService.create(user).subscribe((data)=> {
      this.dialogRef.close(true);
    }, error => {
      this._toast.error(error.error.message);
    })

  }

}
