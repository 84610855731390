<mat-toolbar class="topbar">
    <div class="topbar__container">
        <button mat-icon-button (click)="toggleSideBar()">
            <mat-icon>menu</mat-icon>
        </button>
        <img class="topbar__logo" src="assets/flack-logo.png" alt="Falck logo"  />
        <span class="app-name">MONITORING</span>
        <sup [matTooltip]="getAppMeta()" class="app-version">{{appMeta.version}}</sup>
    </div>

    <button
            mat-icon-button
            matTooltip="Logout"
            (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
    </button>


</mat-toolbar>
<!--<div class="topbar">-->
<!--    <img class="topbar__logo" src="assets/flack-logo.png" alt="Falck logo"  />-->
<!--    <span class="app-name">MONITORING</span>-->
<!--    <sup [matTooltip]="getAppMeta()" mat class="app-version">{{appMeta.version}}</sup>-->

<!--    <div class="topbar__nav">-->
<!--        <app-navigation></app-navigation>-->
<!--    </div>-->
<!--    <div class="topbar__nav-secondary">-->
<!--        <button mat-icon-button matTooltip="Users" routerLink="users"><mat-icon fontSet="material-icons-outlined">group</mat-icon></button>-->
<!--        <button mat-icon-button matTooltip="Logout" (click)="logout()"><mat-icon fontSet="material-icons-outlined">power_settings_new</mat-icon></button>-->
<!--    </div>-->

<!--</div >-->
