<div  class="container">
    <header>
        <h1>Users</h1>
        <div class="top-bar">
            <div class="top-bar__filters">

            </div>
            <div class="top-bar__actions">
                <button mat-flat-button matTooltip="Create user" (click)="createUser()"  color="accent" [disabled]="!isSuperAdminOrAdmin()">
                    <mat-icon class="btn-icon" fontSet="material-icons-outlined">person_add</mat-icon>
                </button>
            </div>
        </div>
    </header>



    <mat-card>
        <mat-card-content>
            <table mat-table
                   [dataSource]="dataSource" matSort matSortActive="surname" matSortDisableClear matSortDirection="asc"
                   (matSortChange)="sortData($event)">


                <ng-container matColumnDef="avatar">
                    <th mat-header-cell  *matHeaderCellDef class="th-center"></th>
                    <td mat-cell *matCellDef="let row">{{row.avatar}}</td>
                </ng-container>

                <ng-container matColumnDef="fullName">
                    <th mat-header-cell mat-sort-header="surname" *matHeaderCellDef class="th-center">Full Name</th>
                    <td mat-cell *matCellDef="let row">{{row.name}} {{row.surname}}</td>
                </ng-container>


                <ng-container matColumnDef="username">
                    <th mat-header-cell mat-sort-header="username" *matHeaderCellDef class="th-center">Username</th>
                    <td mat-cell *matCellDef="let row">{{row.username}}</td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell mat-sort-header="email" *matHeaderCellDef class="th-center">Email</th>
                    <td mat-cell *matCellDef="let row">{{row.email}}</td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell mat-sort-header="role" *matHeaderCellDef class="th-center">Role</th>
                    <td mat-cell *matCellDef="let row">{{getRoleName(row.role)}}</td>
                </ng-container>

                <ng-container matColumnDef="active">
                    <th mat-header-cell mat-sort-header="active" *matHeaderCellDef class="th-center">Active</th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon class="u-color-green" *ngIf="row.active">verified_user</mat-icon>
                        <mat-icon color="warn" *ngIf="!row.active">close</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastLogin">
                    <th mat-header-cell mat-sort-header="lastLogin" *matHeaderCellDef class="th-center">Last Login</th>
                    <td mat-cell *matCellDef="let row">{{row.lastLoginAt | date:'dd MMM y, HH:mm:ss'}}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button matTooltip="Edit" (click)="editUser(row)">
                            <mat-icon>edit</mat-icon></button>
                        <button mat-icon-button matTooltip="Delete" color="warn" (click)="deleteUser(row)" [disabled]="!isSuperAdmin()">
                            <mat-icon>delete_forever</mat-icon></button>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

            <ng-container *ngIf="totalCount <= 0">
                <div class="no-data">
                    <p>IT LOOKS THERE IS NO DATA YET...</p>
                </div>

            </ng-container>
            <mat-paginator
                    *ngIf="totalCount > 0"
                    [length]="totalCount"
                    color="accent"
                    [pageSize]="requestParams.limit"
                    [pageIndex]="requestParams.page"
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="pageChange($event)"
            ></mat-paginator>
        </mat-card-content>
    </mat-card>
</div>
