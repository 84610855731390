import { Pipe, PipeTransform } from '@angular/core';
import { TransformLetterType, TransformLetterTypes } from '../../../models/transform-monitor.model';

@Pipe({name: 'transformLetterName'})
export class TransformLetterTypesPipe implements  PipeTransform{
    transform(letterType: TransformLetterType): string {


          let result:string ='unknown';

          switch(letterType) {
              case TransformLetterTypes.CARD_REGISTRATION:
                  result = 'Card Registration';
                  break;
              case TransformLetterTypes.CREDIT_MEMO:
                  result = 'Credit Memo';
                  break;
              case TransformLetterTypes.DUNNING:
                  result = 'Dunning';
                  break;
              case TransformLetterTypes.INFORMATION:
                  result = 'Information';
                  break;
              case TransformLetterTypes.INVOICE:
                  result = 'Invoice';
                  break;
              case TransformLetterTypes.INVOICE_S:
                  result = 'Invoice';
                  break;
              case TransformLetterTypes.PBS_REGISTRATION:
                  result = 'PBS Registration';
                  break;
              case TransformLetterTypes.REACTIVATE_PLAN_LETTER:
                  result = 'Reactivate Plan Letter';
                  break;
              case TransformLetterTypes.REFUND_INFORMATION:
                  result = 'Refund Information';
                  break;
              case TransformLetterTypes.RENEWAL:
                  result = 'Renewal';
                  break;

          }

          return result;
    }
}
