import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toast: HotToastService) {}

  public error(message:string= 'Something goes wrong :('): void {
    this.toast.error(message);
  }

  public success(message:string = 'Everything went well '): void {
    this.toast.success(message);
  }

  public warn(message:string = "Hm... something is not right."): void {
    this.toast.warning(message);
  }
}
