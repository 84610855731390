import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RequestParams } from '../../../models/list';
import { Observable } from 'rxjs';
import { ResponseList } from '../../../models/response.model';
import { NetaxeptTransaction } from '../../../models/netaxept-transaction.model';
import { MonitorNotification } from '../../../models/monitor-notification.model';

@Injectable({
  providedIn: 'root'
})
export class MonitorsNotificationsService {

  private _apiUrl: string = environment.apiUrl;

  constructor(
      private _http:HttpClient
  ) { }

  public getNotifications(params:RequestParams):Observable<ResponseList<MonitorNotification>> {
    return this._http.get<ResponseList<MonitorNotification>>(`${this._apiUrl}/notifications`, {params});
  }

}
