<div class="container">
    <mat-toolbar color="primary" class="header">
        <h1>Health Metrics</h1>
    </mat-toolbar>

    <main class="content">
        <mat-card>
            <mat-card-header>
                <mat-card-title class="header-title">Netaxept</mat-card-title>
                <mat-card-subtitle>Overall health metrics</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div>
                    <h1>Not implemented :( </h1>
                </div>
            </mat-card-content>
        </mat-card>


        <mat-card>
            <mat-card-header>
                <mat-card-title class="header-title">Letter Generation</mat-card-title>
                <mat-card-subtitle>Overall health metrics</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div>
                    <h1>Not implemented :( </h1>
                </div>
            </mat-card-content>
        </mat-card>
    </main>
</div>
