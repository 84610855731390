import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment'
import { TimeUnit, TimeUnits } from '../../../../models/units.model';
import { DateTime } from 'luxon';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-netaxept-main',
  templateUrl: './netaxept-main.component.html',
  styleUrls: ['./netaxept-main.component.scss']
})
export class NetaxeptMainComponent implements OnInit {
  public allTransactionTableStatusFilter = '';
  public allTransactionTableIssuerFilter = '';
  public allTransactionTableAmountFromFilter = '';
  public allTransactionTableAmountToFilter = '';
  public allTransactionChartBinFilter = 1;
  public allTransactionChartUnitFilter:TimeUnit = TimeUnits.HOUR;

  public dateRange!:FormGroup;


  ngOnInit() {
    const start = DateTime.now().toUTC().minus({day: 7}).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISO();
    const end = DateTime.now().toUTC().set({hour: 23, minute: 59, second: 59, millisecond: 999}).toISO();

    this.dateRange = new FormGroup({
      start: new FormControl<Date | string | null>(start),
      end: new FormControl<Date | string | null>(end)
    })
  }

  public clearMainFilters() {
    this.dateRange.get('start')?.setValue('');
    this.dateRange.get('end')?.setValue('');
  }

  public clearTransactionTableFilters() {
    this.allTransactionTableStatusFilter = '';
    this.allTransactionTableIssuerFilter = '';
    this.allTransactionTableAmountFromFilter = '';
    this.allTransactionTableAmountToFilter = '';
  }

  public gotoNetaxeptPanel() {
    window.open(environment.netaxeptPanelUrl, '_blank');
  }
}
