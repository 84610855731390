import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RequestParams } from '../../../models/list';
import { Observable } from 'rxjs';
import { ResponseList } from '../../../models/response.model';
import { AriaWfLetterItem } from '../../../models/aria-wf-letter-item.model';
import { AriaWfLetterXml } from '../../../models/aria-wf-letter-xml.model';

@Injectable({
  providedIn: 'root'
})
export class AriaWfService {


  private _apiUrl: string = environment.apiUrl;

  constructor(
      private _http:HttpClient
  ) { }

  public getLettersItems(params:RequestParams):Observable<ResponseList<AriaWfLetterItem>> {
    return this._http.get<ResponseList<AriaWfLetterItem>>(`${this._apiUrl}/aria-wf/letters-items`, {params});
  }

  public getLettersXmls(params:RequestParams):Observable<ResponseList<AriaWfLetterXml>> {
    return this._http.get<ResponseList<AriaWfLetterXml>>(`${this._apiUrl}/aria-wf/letters-xmls`, {params});
  }
}

