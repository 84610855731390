import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { HotToastModule } from '@ngneat/hot-toast';
import { ToastService } from './services/toast.service';
import { MatDialogModule } from '@angular/material/dialog';
import { DeleteConfiramtionDialogComponent } from './components/delete-confiramtion-dialog/delete-confiramtion-dialog.component';
import { PortalModule } from '@angular/cdk/portal';
import { MatChipsModule } from '@angular/material/chips';
import { LineChartThreeTypesComponent } from './components/line-chart-three-types/line-chart-three-types.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { CustomDateAdapter } from './common/custom-date.adapter';
import { MoitorNotificationFactsPipe } from './pipes/moitor-notification-facts.pipe';
import { TransformLetterTypesPipe } from './pipes/transform-letter-types.pipe';
import { AriaWfLetterItemsStatusesPipe } from './pipes/aria-wf-letter-items-statuses.pipe';


const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always',
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@NgModule({
  declarations: [DeleteConfiramtionDialogComponent, LineChartThreeTypesComponent, MoitorNotificationFactsPipe, TransformLetterTypesPipe, AriaWfLetterItemsStatusesPipe],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    NgxEchartsModule.forRoot({
       echarts: () => import('echarts'),
    }),

  ],
  exports: [CommonModule, RouterModule, BrowserModule, BrowserAnimationsModule, MatInputModule, NgxEchartsModule, MatCardModule, MatChipsModule, MatButtonModule, MatSidenavModule, MatListModule, MatIconModule, MatTooltipModule, MatToolbarModule, ReactiveFormsModule, HttpClientModule, MatStepperModule, MatProgressSpinnerModule, MatTableModule, MatPaginatorModule, MatSortModule, MatSelectModule, MatTooltipModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule, PortalModule, FormsModule, LineChartThreeTypesComponent, MoitorNotificationFactsPipe, TransformLetterTypesPipe, AriaWfLetterItemsStatusesPipe],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    ToastService,
    MoitorNotificationFactsPipe,
    TransformLetterTypesPipe,
    AriaWfLetterItemsStatusesPipe
  ]
})
export class SharedModule { }
