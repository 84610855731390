import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PAGE_SIZE_OPTIONS, RequestParams, SortDirections } from '../../../../models/list';
import { NetaxeptTransaction } from '../../../../models/netaxept-transaction.model';
import { NetaxeptService } from '../../services/netaxept.service';
import { ToastService } from '../../../shared/services/toast.service';
import { ResponseList } from '../../../../models/response.model';
import { Sort } from '@angular/material/sort';
import * as moment from 'moment';
import { TimeTools } from '../../../shared/common/time-tools';

@Component({
  selector: 'app-transactions-table',
  templateUrl: './transactions-table.component.html',
  styleUrls: ['./transactions-table.component.scss']
})
export class TransactionsTableComponent implements OnInit, OnChanges{
  @Input() statusFilter = '';
  @Input() issuerFilter = '';
  @Input() amountFromFilter:number | string = '';
  @Input() amountToFilter:number | string = '';
  @Input() dateStartFilter!:Date | string;
  @Input() dateEndFilter!:Date | string;

  public displayedColumns: string[] = ['timestamp', 'transactionId', 'maskedPan', 'orderNumber', 'status', 'errors', 'errorDescription', 'amount', 'issuer',];
  public dataSource: NetaxeptTransaction[] = [];
  public totalCount = 0;
  public pageSizeOptions = PAGE_SIZE_OPTIONS;
  public requestParams: RequestParams = {
    page: 0,
    limit: 15,
    sort: 'timestamp',
    order: SortDirections.DESC,
    'errors-detailed': true,
    status: this.statusFilter,
    issuer: this.issuerFilter,
    amountFrom:this.amountFromFilter,
    amountTo: this.amountToFilter,
    dateFrom:this.dateStartFilter,
    dateTo: this.dateEndFilter
  };


  constructor(
      private _netaxeptService:NetaxeptService,
      private _toast:ToastService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this._getListData();
  }

  ngOnChanges(changes: SimpleChanges) {
    const dates = TimeTools.alignUTCDatesFromTo(this.dateStartFilter, this.dateEndFilter);

    if(!dates) {
      return;
    }

    this.requestParams['status'] = this.statusFilter;
    this.requestParams['issuer'] = this.issuerFilter;
    this.requestParams['amountFrom'] = this.amountFromFilter;
    this.requestParams['amountTo'] = this.amountToFilter;
    this.requestParams['dateFrom'] = dates.from;
    this.requestParams['dateTo'] = dates.to;
    this.requestParams.page = 0;
    this._getListData()
  }

  private _getListData() {
    this._netaxeptService.getTransactions(this.requestParams).subscribe( (response:ResponseList<NetaxeptTransaction>) => {
      this.dataSource = response.data;
      this.totalCount = response.total;
      this._cd.detectChanges();
    });
  }

  public pageChange(event: any) {
    this.requestParams.page = event.pageIndex;
    this.requestParams.limit = event.pageSize;
    this._getListData();
  }

  public sortData(sort:Sort) {
    this.requestParams.sort = sort.active;
    this.requestParams.order = sort.direction;
    this.requestParams.page = 0;
    this._getListData();
  }
}
