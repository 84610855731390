import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { FormControl, FormGroup } from '@angular/forms';
import { LettersGenerationService } from '../../services/letters-generation.service';
import * as FileSaver from 'file-saver';

import { RequestParams } from '../../../../models/list';
import { TimeTools } from '../../../shared/common/time-tools';
import { LetterVsByTypeStats, LetterVsStats } from '../../../../models/letter-generation-stats.model';

@Component({
  selector: 'app-letters-generation-main',
  templateUrl: './letters-generation-main.component.html',
  styleUrls: ['./letters-generation-main.component.scss']
})
export class LettersGenerationMainComponent implements OnInit  {
  public dateRange!:FormGroup;
  public monitorTableStatusFilter:string = '';
  public monitorTableStateFilter:string = '';
  public monitorTableLetterTypeFilter:string = '';
  public monitorTableAriaWfIdFilter:string = '';
  public monitorTableAwaitingCreditMemoFilter:boolean | string = '';

  public ariaWfLetterItemTableMissingStatusFilter:string = '';
  public ariaWfLetterItemTableLetterMissingTypeFilter:string = '';
  public ariaWfLetterItemTableMissingAriaWfIdFilter:string = '';

  public ariaWfLetterXmlTableMissingStatusFilter:string = '';
  public ariaWfLetterXmlTableMissingLetterTypeFilter:string = '';
  public ariaWfLetterXmlTableMissingAriaWfIdFilter:string = '';

  public ariaWfLetterItemTableStatusFilter:string = '';
  public ariaWfLetterItemTableLetterTypeFilter:string = '';
  public ariaWfLetterItemTableAriaWfIdFilter:string = '';
  public ariaWfLetterItemTableAwaitingCreditMemoFilter:boolean | string = '';

  public ariaWfLetterXmlTableStatusFilter:string = '';
  public ariaWfLetterXmlTableLetterTypeFilter:string = '';
  public ariaWfLetterXmlTableAriaWfIdFilter:string = '';

  public missingItemVsXml!:LetterVsStats;
  public missingXmlVsTransform!:LetterVsStats;
  public missingItemVsXmlByType!:LetterVsByTypeStats;
  public missingXmlVsTransformByType!:LetterVsByTypeStats;

  constructor(
      private _letterGenerationService: LettersGenerationService,
      private _cd:ChangeDetectorRef
  ) {}



  ngOnInit() {

    const start = DateTime.now().toUTC().minus({day: 7}).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISO();
    const end = DateTime.now().toUTC().set({hour: 23, minute: 59, second: 59, millisecond: 999}).toISO();

    this.dateRange = new FormGroup({
      start: new FormControl<Date | string | null>(start),
      end: new FormControl<Date | string | null>(end)
    })

    this._getStats();

  }

  public clearMainFilters() {
    this.dateRange.get('start')?.setValue('');
    this.dateRange.get('end')?.setValue('');
  }

  public clearMonitorsTableFilters() {
    this.monitorTableStatusFilter = '';
    this.monitorTableStateFilter = '';
    this.monitorTableLetterTypeFilter = '';
    this.monitorTableAwaitingCreditMemoFilter = '';
  }

  public datesChanged() {
    if(!this.dateRange.getRawValue().end) {
      return;
    }
    this._getStats();
  }

  private _getStats() {

    const dates = TimeTools.alignUTCDatesFromTo(this.dateRange.getRawValue().start, this.dateRange.getRawValue().end);

    const requestParams:RequestParams = {
      dateFrom: dates?.from,
      dateTo: dates?.to,
    }

    this._letterGenerationService.getAriaItemVsAriaXmlMissingStats(requestParams).subscribe(data => {
      this.missingItemVsXml = data;
      this._cd.detectChanges();
    });

    this._letterGenerationService.getAriaItemVsAriaXmlMissingByLetterTypeStats(requestParams).subscribe(data => {
      this.missingItemVsXmlByType = data;
      this._cd.detectChanges();
    });

    this._letterGenerationService.getAriaXmlVsTransformMissingStats(requestParams).subscribe(data => {
      this.missingXmlVsTransform = data;
      this._cd.detectChanges();
    });

    this._letterGenerationService.getAriaXmlVsTransformMissingByLetterTypeStats(requestParams).subscribe(data => {
      this.missingXmlVsTransformByType = data;
      this._cd.detectChanges();
    });
  }


  public getMissingItemsCSV() {

    const dates = TimeTools.alignUTCDatesFromTo(this.dateRange.getRawValue().start, this.dateRange.getRawValue().end);

    const requestParams:RequestParams = {
      dateFrom: dates?.from,
      dateTo: dates?.to,
      letterType: this.ariaWfLetterItemTableLetterMissingTypeFilter,
      sort: 'executionTime',
      order:'asc'
    }

    this._letterGenerationService.getAriaItemVsAriaXmlMissingDetailsCsv(requestParams).subscribe( (data:Blob) => {
      FileSaver.saveAs(data, 'Missing_Aria_WF_Items_Table.csv');
    })
  }

  public getMissingXmlCSV() {

    const dates = TimeTools.alignUTCDatesFromTo(this.dateRange.getRawValue().start, this.dateRange.getRawValue().end);

    const requestParams:RequestParams = {
      dateFrom: dates?.from,
      dateTo: dates?.to,
      letterType: this.ariaWfLetterXmlTableMissingLetterTypeFilter,
      sort: 'createDate',
      order:'asc'
    }

    this._letterGenerationService.getAriaXmlVsTransformMissingDetailsCsv(requestParams).subscribe( (data:Blob) => {
      FileSaver.saveAs(data, 'Missing_Aria_WF_XML_Table.csv');
    })
  }
}
