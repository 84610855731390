import {
  ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { TimeUnit, TimeUnits } from '../../../../models/units.model';
import { NetaxeptService } from '../../services/netaxept.service';
import { NetaxeptTransactionAggregated } from '../../../../models/netaxept-transaction-aggregated.model';
import { EChartsOption } from 'echarts';
import { NetaxeptTransactionStatuses } from '../../../../models/netaxept-transaction.model';
import { DateTime } from 'luxon';
import { TimeTools } from '../../../shared/common/time-tools';

@Component({
  selector: 'app-transactions-chart',
  templateUrl: './transactions-chart.component.html',
  styleUrls: ['./transactions-chart.component.scss']
})
export class TransactionsChartComponent implements  OnInit, OnChanges {
  @Input() dateFrom!: Date | string ;
  @Input() dateTo!: Date | string ;
  @Input() bin!: number;
  @Input() unit!: TimeUnit;

  @ViewChild('transactionsChart') transactionsChart?:ElementRef;

  public chartData!:NetaxeptTransactionAggregated[];
  public options!: EChartsOption;

  constructor(private _netaxeptService: NetaxeptService, private _cd: ChangeDetectorRef) {
  }


  ngOnChanges(changes: SimpleChanges) {
    this._getData();
  }

  private _getData() {

    const dates = TimeTools.alignUTCDatesFromTo(this.dateFrom, this.dateTo);
    if(!dates) {
      return;
    }


    this._netaxeptService.getAggregatedTransactions(dates.from, dates.to, this.unit, this.bin).subscribe((data) => {
      this.chartData = data.data;
      this._updateChart();
      this._cd.detectChanges();
    });
  }


  ngOnInit(): void {
  }

  private _updateChart() {
    let xAxisData = [];
    const warnings = [];
    const errors = [];
    const oks = [];
    const all = [];


    for(let rowData of this.chartData) {
      switch(this.unit) {
        case TimeUnits.MINUTE:
        case TimeUnits.HOUR:
          let formattedMinuteHour = DateTime.fromISO(rowData.timestamp).toUTC().toFormat('HH:mm dd/MM/yy');
          xAxisData.push(formattedMinuteHour);
          break;

        case TimeUnits.DAY:
          let formattedDay = DateTime.fromISO(rowData.timestamp).toUTC().toFormat('dd/MM/yyyy');
          xAxisData.push(formattedDay);
          break;

        case TimeUnits.WEEK:
          let formattedWeek = DateTime.fromISO(rowData.timestamp).toUTC().toFormat("'Week' WW yyyy");
          xAxisData.push(formattedWeek);
          break;

        case TimeUnits.MONTH:
          let formattedMonth = DateTime.fromISO(rowData.timestamp).toUTC().toFormat('LLL yyyy');
          xAxisData.push(formattedMonth);
          break;

        case TimeUnits.YEAR:
          let formattedYear = DateTime.fromISO(rowData.timestamp).toUTC().toFormat('yyyy');
          xAxisData.push(formattedYear);
          break;


      }

      all.push(rowData.total);
      for (let status of rowData.statuses) {
          switch(status.status) {
            case NetaxeptTransactionStatuses.ERROR:
               errors.push(status.count);
              break;
            case NetaxeptTransactionStatuses.WARNING:
              warnings.push(status.count);
              break;
            case NetaxeptTransactionStatuses.OK:
              oks.push(status.count);
              break;
          }
        }

    }

    this.options = {
      darkMode: true,
      grid: {
        left: 50,
        // top: 0,
        right: 50,
        // bottom: 0
      },
      legend: {
        data: ['OK', 'WARNINGS', 'ERRORS', 'ALL'],
        align: 'left',
        textStyle: {
          color: '#FEFEFE'
        }
      },
      tooltip: {},
      xAxis: {
        data: xAxisData,
        silent: false,
        splitLine: {
          show: false,
        },
        type: 'category',
        boundaryGap: true,
      },
      yAxis: {},
      series: [
        {
        name: 'OK',
        type: 'bar',
          emphasis: {
            focus: 'series'
          },
        data: oks,
        animationDelay: idx => idx * 10 + 100,
        color: '#0db50d'
        },
        {
        name: 'WARNINGS',
        type: 'bar',
          emphasis: {
            focus: 'series'
          },
        data: warnings,
        animationDelay: idx => idx * 10 + 100,
        color: '#d49803'
        },
        {
          name: 'ERRORS',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: errors,
          animationDelay: idx => idx * 10 + 100,
          color: '#d30001'
        },
        {
          name: 'ALL',
          type: 'line',
          emphasis: {
            focus: 'series'
          },
          data: all,
          animationDelay: idx => idx * 10 + 100

        },
      ], animationEasing: 'elasticOut', animationDelayUpdate: idx => idx * 5,
    };

  }

}
