import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { NetaxeptErrorCode } from '../../../../models/netaxept-error.model';
import { NetaxeptService } from '../../services/netaxept.service';
import { EChartsOption } from 'echarts';
import { RequestParams } from '../../../../models/list';
import { TimeTools } from '../../../shared/common/time-tools';

@Component({
  selector: 'app-error-types-chart',
  templateUrl: './error-types-chart.component.html',
  styleUrls: ['./error-types-chart.component.scss']
})
export class ErrorTypesChartComponent  implements OnInit, OnChanges {

  @Input() dateFrom!: Date | string;
  @Input() dateTo!: Date | string;

  @ViewChild('errorsTypesChart') chart?:ElementRef;

  public chartData!:NetaxeptErrorCode[];
  public options!: EChartsOption;
  public requestParams: RequestParams = {
    dateFrom: this.dateFrom,
    dateTo: this.dateTo,
    page: 0,
    limit: -1,
  }

  constructor(private _netaxeptService: NetaxeptService, private _cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    // this._getData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._getData();
  }

  private _getData() {
    const dates = TimeTools.alignUTCDatesFromTo(this.dateFrom, this.dateTo);
    if(!dates) {
      return;
    }
    this.requestParams['dateFrom'] = dates.from;
    this.requestParams['dateTo'] = dates.to;
    this.requestParams.page = 0;


    this._netaxeptService.getAccumulatedErrors(this.requestParams).subscribe((data) => {
      this.chartData = data.data;
      this._updateChart();
    });
  }

  private _updateChart() {

    const data:{value:number, name:string}[] = []

    for(let item of this.chartData) {
        data.push({
          name: item.responseCode + ' - ' + item.responseMessage,
          value: item.count
        });
    }

    this.options = {
      darkMode: true,
      color: [
        '#5470c6', '#91cc75', '#fac858', '#ee6666','#73c0de','#3ba272','#fc8452','#9a60b4','#ea7ccc'
      ],
      colorBy: 'series',
      gradientColor: ['#f6efa6','#d88273','#bf444c'],
      series: {
        data: data,
        type: 'pie',
        radius: [50, 150],
        center: ['50%', '50%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 8
        },
      },
      aria:{
        decal: {
          show: true,
          decals:[
            {
              color: "rgba(0, 0, 0, 0.2)",
              dashArrayX: [1,0],
              dashArrayY: [2,5],
              symbolSize:1,
              rotation: 0.5235987755982988,
              symbol: 'rect',
              symbolKeepAspect: true,
              maxTileHeight: 512,
              maxTileWidth: 512
            },
            {
              color: "rgba(0, 0, 0, 0.2)",
              dashArrayX: [[8,8],[0,8,8,0]],
              dashArrayY: [6,0],
              symbolSize:0.8,
              rotation: 0,
              symbol: 'circle',
              symbolKeepAspect: true,
              maxTileHeight: 512,
              maxTileWidth: 512
            },
            {
              color: "rgba(0, 0, 0, 0.2)",
              dashArrayX: [[6,6],[0,6,6,0]],
              dashArrayY: [6,0],
              symbolSize:1,
              rotation: 0,
              symbol: 'rect',
              symbolKeepAspect: true,
              maxTileHeight: 512,
              maxTileWidth: 512
            },
            {
              color: "rgba(0, 0, 0, 0.2)",
              dashArrayX: [[1,0],[1,6]],
              dashArrayY: [1,0,6,0],
              symbolSize:1,
              rotation: 0.7853981633974483,
              symbol: 'rect',
              symbolKeepAspect: true,
              maxTileHeight: 512,
              maxTileWidth: 512
            },

          ]
        }
      },
      tooltip: {
        show: true
      },
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0
      }
    };
  }

}
