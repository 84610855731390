<div class="container">

    <div class="container__content card">
        <div>
            <div class="logo">
                <img src="assets/flack-logo.png" alt="Flack A/S logo"/>
                <span>CTS Monitoring</span>
            </div>
            <div class="name">

            </div>

            <div>
                <h3>Provide your new password and click below.</h3>
                <form name="set-password-from" [formGroup]="setNewPasswordForm" novalidate autocomplete="off" (ngSubmit)="onSubmit()" class="set-password-form">
                    <mat-form-field color="primary">
                        <mat-label>Password</mat-label>
                        <input
                                matInput
                                autocomplete="off"
                                id="password"
                                name="password"
                                placeholder="Password"
                                formControlName="password"
                                type="password"
                                required
                        />
                    </mat-form-field>

                    <mat-form-field color="primary">
                        <mat-label>Retype Password</mat-label>
                        <input
                                matInput
                                autocomplete="off"
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Retype Password"
                                formControlName="confirmPassword"
                                type="password"
                                required
                        />
                    </mat-form-field>

                    <div class="return-to-login">
                        <a class="return-to-login__link" routerLink="/auth/sign-in">
                            * Sign in
                        </a>
                    </div>

                    <button
                            mat-flat-button
                            color="primary"
                            class="submit-button"
                            type="submit"
                            [disabled]="setNewPasswordForm.invalid"
                    >SET PASSWORD
                    </button>

                    <div class="footer">
                        <span><strong>v{{version}}</strong></span>
                        <span>powered by <a href="mailto:piotr.skolysz@falck.com" target="_blank" rel="noopener nofollow noreferrer">Piotr Skołysz</a></span>
                    </div>

                </form>
            </div>
        </div>

    </div>

</div>

