<app-top-bar (sidebarToggled)="sideBarOpen = !sideBarOpen"></app-top-bar>
<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen" class="panel-left">
        <app-navigation (menuSelected)="sideBarOpen = false"></app-navigation>
    </mat-drawer>

    <mat-drawer-content class="panel-content" >
        <router-outlet></router-outlet>
    </mat-drawer-content>

    <!--  <mat-drawer position="end" #drawer mode="over" class="panel-drawer" disableClose [autoFocus]="false">-->
    <mat-drawer position="end" #drawer mode="over" class="panel-drawer" disableClose [autoFocus]="false">
        <ng-template [cdkPortalOutlet]="sidePanelService.panelPortal | async"></ng-template>
        <button matTooltip="Close window" class="panel-drawer__close" type="button" [autofocus]="true" mat-icon-button (click)="sidePanelService.close()"><mat-icon color="accent">close</mat-icon></button>
    </mat-drawer>

</mat-drawer-container>


<!--<div class="content">-->
<!--    <router-outlet></router-outlet>-->
<!--</div>-->
