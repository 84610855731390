import { Component, EventEmitter, Output } from '@angular/core';
import { APP_META } from '../../../../../version';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
  @Output() sidebarToggled: EventEmitter<void> = new EventEmitter();

  public appMeta = APP_META;

  constructor(
      private _authService:AuthService
  ) {
  }

  public getAppMeta(): string{
    const pipe: DatePipe = new DatePipe('en-GB');
    const date = pipe.transform(this.appMeta.buildDate, 'medium')
    return 'Build date: '+ date +'\nCommit hash: '+ this.appMeta.commitHash.substring(0, 10);
  }

  public toggleSideBar(): void {
    this.sidebarToggled.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  public logout() {
    this._authService.logout();
  }
}
