import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../../shared/services/toast.service';
import { APP_META } from '../../../../../version';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public forgotPasswordForm!: FormGroup;
  public version = APP_META.version;

  constructor(
      private _fb: FormBuilder,
      private _router: Router,
      private _route: ActivatedRoute,
      private _authService:AuthService,
      private _toastService: ToastService
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public onSubmit() {
    if (!this.forgotPasswordForm.valid) {
      return;
    }

    this._authService.forgetPassword(this.forgotPasswordForm.getRawValue().email).subscribe((data) => {
      this._router.navigate(['auth', 'sign-in']).then(() => {
        this.forgotPasswordForm.reset();
        this._toastService.success('Email with password reminder was sent.');
      });
    }, (error) => {
      console.log(error);
      this._toastService.warn(error.error.error?.message)
    })
  }
}
